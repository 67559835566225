import React, { FC } from 'react'
import {
    Button,
    Grid,
    Divider,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    Chip,
    Stack,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Key from '@mui/icons-material/Key'

import CharcoalParagraph from '../../styled/Paragraph'
import { RelativeTimeDisplay } from '../RelativeTimeDisplay'
import { PersonalAccessToken } from '../../services/personalAccessTokens'

export interface TokenListHeaderProps {
    onCreate: () => void
}

export const TokenListHeader: FC<TokenListHeaderProps> = (props: TokenListHeaderProps) => {
    return (
        <>
            <Grid container justifyContent="space-between">
                <Typography sx={{ mt: 2, mb: 1, fontWeight: 'bold' }} variant="h6" component="div">
                    Personal Access Tokens
                </Typography>
                <Button
                    onClick={props.onCreate}
                    color="primary"
                    sx={{ padding: '0.25em 2em 0.25em', marginBottom: '1em' }}
                >
                    New Token
                </Button>
            </Grid>
            <Divider />
            <Typography sx={{ mt: 2, mb: 1, fontWeight: 'bold' }} variant="subtitle1" component="p">
                This is a list of access tokens associated with your account. Remove any tokens that
                you do not recognize
            </Typography>
        </>
    )
}

export interface TokenListProps {
    pats: Array<PersonalAccessToken>
    onEdit: (row: PersonalAccessToken) => void
    onDelete: (row: PersonalAccessToken) => void
}

export const TokenList: FC<TokenListProps> = (props: TokenListProps) => {
    const items = props.pats.map((pat: PersonalAccessToken) => {
        return (
            <ListItemRow
                key={pat.shortToken}
                row={pat}
                editRow={props.onEdit}
                removeRow={props.onDelete}
            />
        )
    })
    return <List sx={{ width: 'fit-content', margin: 'auto', marginBottom: '-1em' }}>{items}</List>
}

interface ListItemRowProps {
    row: PersonalAccessToken
    editRow: (row: PersonalAccessToken) => void
    removeRow: (row: PersonalAccessToken) => void
}

export function ListItemRow(props: ListItemRowProps) {
    const { row, editRow, removeRow } = props

    return (
        <ListItem>
            <ListItemIcon sx={{ padding: '1em', paddingLeft: '0' }}>
                <Stack display="flex" justifyContent="center" alignItems="center">
                    <Key
                        fontSize="large"
                        sx={{
                            width: '2.5em',
                            height: '1.5em',
                            transform: 'rotate(45deg)',
                        }}
                    />
                    <Chip label="API" variant="outlined" sx={{ width: 'fit-content' }} />
                </Stack>
            </ListItemIcon>
            {/*
                The size of 26em contains the max length token name (50 chars) to fit nicely on one
                line along with the ability to not have the list look too wide at larger resolutions.
            */}
            <Grid container sx={{ maxWidth: '26em' }}>
                <Grid item sm={12} md={12}>
                    {row.name}
                </Grid>
                <Grid item sm={12} md={12}>
                    {row.shortToken}
                </Grid>
                <Grid item sm={12} md={12}>
                    <CharcoalParagraph style={{ marginBottom: '0.5em' }}>
                        {row.description}
                    </CharcoalParagraph>
                    <CharcoalParagraph style={{ marginBottom: '0' }}>
                        <RelativeTimeDisplay prefix="Added: " timestamp={row.createdAt} />
                    </CharcoalParagraph>
                    <CharcoalParagraph style={{ marginBottom: '1' }}>
                        <RelativeTimeDisplay prefix="Last used: " timestamp={row.lastUsedAt} />
                    </CharcoalParagraph>
                </Grid>
            </Grid>
            <Grid>
                <Grid item sm={4} md={2}>
                    <IconButton
                        data-testid="remove-row-btn"
                        onClick={() => {
                            removeRow(row)
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <IconButton
                        data-testid="edit-row-btn"
                        onClick={() => {
                            editRow(row)
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </ListItem>
    )
}

import React, { FC, ReactElement, useEffect } from 'react'
import { FlowError, FrontendApi, FrontendApiGetFlowErrorRequest } from '@ory/client'
import { AxiosResponse } from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { BannerMessage } from '../components/BannerMessage'
import { newKratosSdk } from '../services/kratos'

export const DEFAULT_INTERNAL_ERROR = 'Internal Server Error'

const errorBannerMessage = (message: string): BannerMessage => ({
    type: 'error',
    message,
})

const useErrorId = (): string | null | undefined => {
    const [searchParams] = useSearchParams()
    return searchParams.get('id')
}

const getError = async (errorId: string): Promise<FlowError> => {
    return new Promise((resolve, reject) => {
        const req: FrontendApiGetFlowErrorRequest = {
            id: errorId,
        }

        newKratosSdk()
            .getFlowError(req)
            .then((res: AxiosResponse<FlowError>) => resolve(res.data))
            .catch((err) => reject(err))
    })
}

const locationFromKratosError = (kratosError: FlowError): string => {
    // This function will eventually support logic for deciding which page
    // to navigate the user to. This logic should be based on the contents of
    // the error, and possible whether the user is logged in or not (which isn't
    // added yet- you'd need to use the `getSession` method from `useContext(AuthContext)`
    // and check that before reaching this function)
    //
    // Until then, this is just hardcoded to redirect the user to the Login page
    return '/login'
}

// This is a helper interface to help typecheck the `error` object within the SelfServiceError
// from kratos. That property is typed as just `object`, which makes type checking in the
// bannerFromKratosError function difficult without a helper interface
interface InnerError {
    status?: string
    reason?: string
}

const bannerFromKratosError = ({ error = {} }: FlowError): BannerMessage => {
    // This function might eventually translate internal errors into a message
    // that makes more sense for the user and their context, but for now we just
    // pass along the error message from kratos
    const innerStatus = (error as InnerError).status
    const innerReason = (error as InnerError).reason
    const message = innerStatus ? `${innerStatus}: ${innerReason}` : DEFAULT_INTERNAL_ERROR
    return errorBannerMessage(message)
}

const SelfServiceAuthError: FC<any> = (): ReactElement => {
    const navigate = useNavigate()
    const errorId = useErrorId()

    const navigateAway = (location: string, banner?: BannerMessage | undefined) => {
        if (banner === undefined) {
            navigate(location)
        } else {
            const navState = { state: {} as any }
            navState.state.banner = banner
            navigate(location, navState)
        }
    }

    useEffect(() => {
        if (errorId === undefined) {
            // The error id hasn't been retrieved from the url params yet
            // (component is still loading) so we just exit early
            return
        }

        if (errorId === null) {
            // No error id was provided in the url params, which means the
            // browser manually navigated to this page and there isn't
            // actually an error to retrieve from the api. Just return to login
            navigateAway('/login')
        } else {
            getError(errorId)
                .then((kratosError) => {
                    const banner = bannerFromKratosError(kratosError)
                    const location = locationFromKratosError(kratosError)
                    navigateAway(location, banner)
                })
                .catch((err) => {
                    // If there's an error while getting the self service error
                    // details from kratos, then there's probably something wrong
                    // with kratos. Best to redirect to the default page (login)
                    // and just show an internal service error message
                    console.debug('Got unexpected error while fetching self-service error:')
                    console.debug(err)
                    navigateAway('/login', errorBannerMessage(DEFAULT_INTERNAL_ERROR))
                })
        }
    }, [errorId])

    return <></>
}

export default SelfServiceAuthError

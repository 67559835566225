import Login from './pages/Login'
import Register from './pages/Register'
import Verify from './pages/Verify'

import { FC } from 'react'
import Logout from './pages/Logout'
import RecoverAccount from './pages/RecoverAccount'
import ChangePassword from './pages/settings/ChangePassword'
import PersonalAccessTokens from './pages/settings/PersonalAccessTokens'
import SelfServiceAuthError from './pages/SelfServiceAuthError'
import Error404 from './pages/Error404'
import RecoverAccountGuard from './pages/RecoverAccountGuard'
import AcceptInvitationGuard from './pages/AcceptInvitationGuard'

interface Route {
    key: string
    title: string
    path: string
    enabled: boolean
    component: FC
    props?: any
}

export const routes: Array<Route> = [
    {
        // While we are still building out an `account` page on the app
        // simply put the user on the login page if they browse to the index
        key: 'account-route',
        title: 'Account',
        path: '/',
        enabled: true,
        component: Login,
    },
    {
        key: 'settings-tokens-route',
        title: 'User Settings: Personal Access Tokens',
        path: '/user/settings/tokens',
        enabled: true,
        component: PersonalAccessTokens,
    },
    {
        key: 'settings-change-password-route',
        title: 'User Settings: Change Password',
        path: '/user/settings/change-password',
        enabled: true,
        component: ChangePassword,
    },
    {
        key: 'login-route',
        title: 'Login',
        path: '/login',
        enabled: true,
        component: Login,
    },
    {
        key: 'logout-route',
        title: 'Logout',
        path: '/logout',
        enabled: true,
        component: Logout,
    },
    {
        key: 'register-route',
        title: 'Register',
        path: '/register',
        enabled: true,
        component: Register,
    },
    {
        key: 'recover-account-route',
        title: 'Recover Account',
        path: '/recover-account',
        enabled: true,
        component: RecoverAccount,
    },
    {
        key: 'recover-account-guard-route',
        title: 'Recover Account',
        path: '/recover-account-guard',
        enabled: true,
        component: RecoverAccountGuard,
    },
    {
        key: 'verification-route',
        title: 'Verify Account',
        path: '/verify',
        enabled: false,
        component: Verify,
    },
    {
        key: 'accept-invitation-guard-route',
        title: 'Accept Invitation',
        path: '/user/settings/invitations/accept',
        enabled: true,
        component: AcceptInvitationGuard,
    },
    {
        key: 'self-service-auth-error',
        title: 'Self Service Auth Error',
        path: '/auth-error',
        enabled: true,
        component: SelfServiceAuthError,
    },
    // Make the default / unmatched path go to the 404 Page
    {
        key: 'error-404',
        title: '404 Not Found',
        path: '*',
        enabled: true,
        component: Error404,
    },
]

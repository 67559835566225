import React, { ReactElement, FC } from 'react'
import { Divider, Typography, List, ListItem, ListItemText } from '@mui/material'
import { CenterDialogButton, DialogProps, TokensDialog, voidClose } from './base'
import { PersonalAccessToken } from '../../services/personalAccessTokens'

export interface DeleteDialogProps extends DialogProps {
    onClose: voidClose
    removeToken: (tokenName: string) => void
    tokenDeleteRow?: PersonalAccessToken
}

const createListItem = (label: string, value: string) => {
    return (
        <ListItem sx={{ paddingY: '0' }}>
            <Typography marginRight="0.5em" component="h6" variant="subtitle1" fontWeight="bold">
                {label}
            </Typography>
            <ListItemText sx={{ fontFamily: 'InterBold' }} primary={value} />
        </ListItem>
    )
}

const DeleteDialog: FC<DeleteDialogProps> = (props: DeleteDialogProps): ReactElement => {
    const { onClose, tokenDeleteRow, open, removeToken } = props

    if (tokenDeleteRow === undefined) {
        return <></>
    }

    const handleDelete = () => {
        removeToken(tokenDeleteRow.shortToken)
        onClose()
    }

    return (
        <TokensDialog
            title={'Are you sure you want to delete this token?'}
            subtitle={
                'This action CANNOT be undone. This will permanently delete the token and if you want to use another in the future, you will need to create one again.'
            }
            closeButton={true}
            onClose={onClose}
            open={open}
        >
            <Divider />
            <List sx={{ padding: '0.5em', width: 'fit-content', margin: 'auto' }}>
                {createListItem('Short Token:', tokenDeleteRow.shortToken)}
                {createListItem('Name:', tokenDeleteRow.name)}
                {createListItem('Description:', tokenDeleteRow.description)}
            </List>
            <CenterDialogButton id="delete-token-btn" text="Delete Token" onClick={handleDelete} />
        </TokensDialog>
    )
}

export default DeleteDialog

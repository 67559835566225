import React, { ReactElement, FC } from 'react'
import { Box, Stack, Typography } from '@mui/material'

import censysLogo from './censys-logo.svg'
import { headerStyle } from '../styled/styles'
import { Banner, BannerMessage } from './BannerMessage'
import { EnvironmentBadge } from './EnvironmentBadge'
import { DefaultSubheader } from './Subheader'

export const LogoHeader: FC = (): ReactElement => {
    return (
        <Box
            component="header"
            sx={{
                alignItems: 'center',
                margin: '0 auto',
                padding: '20px 0 40px 0',
            }}
        >
            <a href="https://censys.io">
                <img width="150px" alt="Censys Logo" title="Censys" src={`${censysLogo}`} />
            </a>
        </Box>
    )
}

interface HeaderProps {
    headerText: string
    subheader?: JSX.Element
    banner?: BannerMessage
}

export const Header: FC<HeaderProps> = ({ headerText, subheader, banner }): ReactElement => {
    const bannerComponent = banner === undefined ? <></> : <Banner {...banner} />

    const subheaderElem = subheader === undefined ? DefaultSubheader : subheader

    return (
        <Stack alignItems="center" fontWeight="bold" textAlign={'center'} style={headerStyle}>
            <EnvironmentBadge />
            <LogoHeader />
            {bannerComponent}
            <Typography
                fontFamily="inherit"
                margin="1.125em 0 0.3em"
                component="h1"
                variant="h4"
                fontWeight="bold"
                letterSpacing="-1px"
            >
                {headerText}
            </Typography>
            {subheaderElem}
        </Stack>
    )
}

export default Header

import axiosFactory, { AxiosInstance, AxiosResponse } from 'axios'

const ACCOUNTS_DOMAIN = window.location.hostname

// The accounts API-UI client

export enum LoginAuthnMethod {
    Password = 1,
    Oidc,
}

export interface OidcOptions {
    hint: string
}

export class LoginMethod {
    method: LoginAuthnMethod
    options: null | OidcOptions = null

    constructor(method: LoginAuthnMethod, options?: OidcOptions) {
        this.method = method
        this.options = options ? options : null
    }
}

export interface ResponseLoginMethod {
    method: string
    hint: string | null
}

export class AccountsApiUiV1 {
    pathPrefix: string = '/api/ui/v1'

    hostname: string = ACCOUNTS_DOMAIN

    axios: AxiosInstance

    constructor(axios: AxiosInstance) {
        this.axios = axios
    }

    private buildRoute(route: string): URL {
        const endpoint = `${this.pathPrefix}/${route}`
        return new URL(endpoint, `${window.location.protocol}//${this.hostname}`)
    }

    async getLoginMethod(email: string): Promise<LoginMethod> {
        const url = this.buildRoute('login-method')
        url.searchParams.append('email', encodeURIComponent(email))

        return this.axios
            .get(url.toString())
            .then((response: AxiosResponse<ResponseLoginMethod>) => {
                const methodStr = response.data.method

                if (methodStr === 'password') {
                    return new LoginMethod(LoginAuthnMethod.Password)
                }

                if (methodStr === 'oidc') {
                    const options: OidcOptions = {
                        hint: response.data.hint!,
                    }

                    return new LoginMethod(LoginAuthnMethod.Oidc, options)
                }

                throw 'got unsupported login method'
            })
    }
}

export const newAccountsApiUiV1 = () => {
    const axios = axiosFactory.create()
    axios.defaults.withCredentials = true
    return new AccountsApiUiV1(axios)
}

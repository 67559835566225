import React, { ReactElement } from 'react'
import { Backdrop, CircularProgress } from '@mui/material'

interface LoadingBackdropProps {
    loading: boolean
}

const progressStyle = { width: '15%', height: '15%', display: 'flex', justifyContent: 'center' }

const LoadingBackdrop = (props: LoadingBackdropProps): ReactElement => {
    return (
        <div data-testid="search-loading-spinner">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={props.loading}
            >
                <CircularProgress style={progressStyle} />
            </Backdrop>
        </div>
    )
}

export default LoadingBackdrop

import { Grid, Paper } from '@mui/material'
import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import { loginRegisterBlockStyle, mainStyle } from './styled/styles'
import { BannerMessage } from './components/BannerMessage'

interface LayoutProps {
    heading: string
    subheader?: JSX.Element
    banner?: BannerMessage
    children?: React.ReactNode
}

interface ContainerProps {
    sm: number
    md: number
    lg: number
    xl: number
    children?: React.ReactNode
}

export function LargeContainer(props: Partial<ContainerProps>) {
    return (
        <CommonContainer sm={9} md={7} lg={7} xl={6} {...props}>
            {props.children}
        </CommonContainer>
    )
}

export function MediumContainer(props: Partial<ContainerProps>) {
    return (
        <CommonContainer sm={12} md={8} lg={4} xl={4} {...props}>
            {props.children}
        </CommonContainer>
    )
}

export function SmallContainer(props: Partial<LayoutProps>) {
    return (
        <CommonContainer sm={9} md={7} lg={5} xl={3} {...props}>
            {props.children}
        </CommonContainer>
    )
}

function CommonContainer(props: ContainerProps) {
    return (
        <Grid container alignItems="center" justifyContent="center">
            <Grid
                item
                sm={props.sm}
                md={props.md}
                lg={props.lg}
                xl={props.xl}
                style={{ padding: '5px 10px 0 10px' }}
            >
                <Paper square sx={{ ...loginRegisterBlockStyle, marginTop: '20px' }}>
                    {props.children}
                </Paper>
            </Grid>
        </Grid>
    )
}

export function Layout(props: LayoutProps) {
    return (
        <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item sm={12} md={8} lg={6} xl={6}>
                <Header
                    headerText={props.heading}
                    subheader={props.subheader}
                    banner={props.banner}
                />
            </Grid>
            <Grid container>
                <main style={mainStyle}>{props.children}</main>
            </Grid>
            <Grid item sm={12} md={8} lg={6} xl={6}>
                <Footer />
            </Grid>
        </Grid>
    )
}

export default Layout

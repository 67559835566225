import { Configuration, FrontendApi } from '@ory/client'
let kratosInstance: FrontendApi

export const newKratosSdk = () => {
    if (kratosInstance !== undefined) {
        return kratosInstance
    }

    kratosInstance = new FrontendApi(
        new Configuration({
            basePath: window.location.origin,
            baseOptions: {
                withCredentials: true,
                timeout: 10000,
            },
        }),
        ''
    )

    return kratosInstance
}

import React, { FC, ReactElement } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { PlainLink } from '../styled/styles'
import { ASM_DOMAIN, SEARCH_DOMAIN } from '../config'
import { constructUtmParamQueryString } from '../helpers/utm'

const siteMapHeaderStyle = {
    fontFamily: `"InterBold", serif`,
}

const linkListStyle = {
    listStyleType: 'none',
    padding: '0px',
}

const unauthedRoutes = [
    ['/login', 'Login'],
    ['/register', 'User Register'],
    ['/recover-account', 'Recover Account'],
    // ['/recover-email', 'Recover Email'],
    // ['/verify', 'Verify Account'],
]

const authedRoutes = [
    ['/logout', 'Logout'],
    ['/user/settings/change-password', 'Settings: Change Password'],
    // ['/user/settings/personal-access-tokens', 'Settings: Personal Access Tokens'],
]

interface SiteMapProps {
    authenticated: boolean
}

const SiteMap: FC<SiteMapProps> = (props: SiteMapProps): ReactElement => {
    const routeLinks = props.authenticated
        ? ([] as string[][]).concat(unauthedRoutes, authedRoutes)
        : unauthedRoutes

    // Keep the UTM params on any generated link
    const [searchParams, _] = useSearchParams()
    const queryString = constructUtmParamQueryString(searchParams)

    return (
        <Grid container style={{ textAlign: 'center', justifyContent: 'center' }}>
            <Grid item xs={4}>
                <div style={siteMapHeaderStyle}>Censys Accounts</div>
                <ul style={linkListStyle}>
                    {routeLinks.map(([href, label], idx) => {
                        const url = `${href}${queryString}`
                        return (
                            <li key={idx}>
                                <PlainLink href={url}>{label}</PlainLink>
                            </li>
                        )
                    })}
                </ul>
            </Grid>

            <Grid item xs={2}>
                <div
                    style={{
                        borderLeft: '1px solid rgba(0,0,0,0.35)',
                        height: '100%',
                        width: '1px',
                        margin: '0 auto',
                    }}
                />
            </Grid>

            <Grid item xs={4}>
                <div style={siteMapHeaderStyle}>External Resources</div>
                <ul style={linkListStyle}>
                    <li>
                        <PlainLink
                            href={`${window.location.protocol}//${ASM_DOMAIN}${queryString}`}
                        >
                            Censys ASM
                        </PlainLink>
                    </li>
                    <li>
                        <PlainLink
                            href={`${window.location.protocol}//${SEARCH_DOMAIN}${queryString}`}
                        >
                            Censys Search
                        </PlainLink>
                    </li>
                    <li>
                        <PlainLink href="https://support.censys.io">Censys Support</PlainLink>
                    </li>
                </ul>
            </Grid>
        </Grid>
    )
}

export default SiteMap

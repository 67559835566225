import { BannerMessage, newErrorBanner, newSuccessBanner } from './BannerMessage'

export const refreshSessionBanner: BannerMessage = newSuccessBanner(
    'This is a privileged action. Please reverify your identity.'
)

export const registrationSuccessfulBanner: BannerMessage = newSuccessBanner(
    'Success! Registration successful. Please check your email for a confirmation link to finish your registration.'
)

export const formInitializationBanner: BannerMessage = newErrorBanner(
    'Failed to initialize form. Please refresh the page.'
)

export const flowErrorBanner: BannerMessage = newErrorBanner(
    'Error! The registration flow was unable to be initialized correctly. Please refresh the page to restart the process.'
)

export const passwordChangedBanner: BannerMessage = newSuccessBanner(
    'Password successfully changed.'
)

export const passwordChangedRedirectBanner: BannerMessage = newSuccessBanner(
    'Password successfully changed. Redirecting back in 5 seconds...'
)

export const verificationFlowExpiredBanner: BannerMessage = newErrorBanner(
    'Verification flow has already expired. Refresh the page if you need a new link sent out.'
)

export const recoveryFlowExpiredBanner: BannerMessage = newErrorBanner(
    'Recovery flow has already expired. Refresh the page if you need a new link sent out.'
)

export const existingSessionBanner: BannerMessage = newSuccessBanner(
    'Successfully detected session. Redirecting...'
)

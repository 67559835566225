// This should be kept in sync with the config on kratos. Helps us
// determine when we should make the user refresh their session.
// Age is in seconds
export const PRIVILEGED_SESSION_MAX_AGE: number = parseInt(
    window._env_.REACT_APP_PRIVILEGED_SESSION_MAX_AGE || '3600'
)

// Hcaptcha site key, defaulting to blank
export const HCAPTCHA_SITE_KEY: string = window._env_.REACT_APP_HCAPTCHA_SITE_KEY ?? ''

// Environment label to use for the Environment badge.
// WARNING: This should not be used as a feature flag
export const ENVIRONMENT_LABEL: string | undefined = window._env_.REACT_APP_ENVIRONMENT

// The domain name for the ASM site
// Undefined behavior if this env var is not set
export const ASM_DOMAIN: string = window._env_.REACT_APP_ASM_DOMAIN!

// The domain name for the Search site
// Undefined behavior if this env var is not set
export const SEARCH_DOMAIN: string = window._env_.REACT_APP_SEARCH_DOMAIN!

// The domain name for the CTF site
// Undefined behavior if this env var is not set
export const CTF_DOMAIN: string = window._env_.REACT_APP_CTF_DOMAIN!

// The domain used for verifying return_to addresses. The hostname for all return_to
// addresses will be required to end with this value.
export const BASE_DOMAIN: string = window._env_.REACT_APP_BASE_DOMAIN!

// A comma-separated list of regular expressions that are used to verify
// return_to URLs. If one of the regexes does not match the URL, the URL will be
// considered invalid.
export const RETURN_TO_URLS_REGEXES: string | undefined =
    window._env_.REACT_APP_RETURN_TO_URL_REGEXES

export const LAUNCHDARKLY_CLIENT_ID: string = window._env_.REACT_APP_LAUNCHDARKLY_CLIENT_ID!

export const PENDO_API_KEY: string | undefined = window._env_.REACT_APP_PENDO_KEY

// Determines whether to redirect users through the Search app after login
// Defaults to true if the environment variable is not set to 'false'
export const REDIRECT_THROUGH_SEARCH: boolean =
    (window._env_.REACT_APP_REDIRECT_THROUGH_SEARCH ?? '').toLowerCase() !== 'false'

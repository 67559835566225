import axiosFactory, { AxiosInstance } from 'axios'
import { SEARCH_DOMAIN, REDIRECT_THROUGH_SEARCH } from '../config'
import safelyNavigate, { defaultReturnTo } from '../helpers/safelyNavigate'
import { constructUtmParamQueryString } from '../helpers/utm'

export interface SearchApi {}

// This endpoint sets the internal sso cookie
const SEARCH_REDIRECT_ENDPOINT = '/accounts-redirect'

// This search client is left over from the backfill implementation. While we no longer
// call backfill from the frontend, it does seem worthwhile keeping the search client
// and its configurations around in case we need it again in the future.
class SearchApiImpl {
    searchHost: string
    axios: AxiosInstance

    constructor(axios: AxiosInstance) {
        this.searchHost = SEARCH_DOMAIN
        this.axios = axios
    }
}

export const newSearchClient = () => {
    const axios = axiosFactory.create()
    axios.defaults.withCredentials = true
    return new SearchApiImpl(axios)
}

export const navigateWithAsmCookie = (returnTo: string | null, searchParams: URLSearchParams) => {
    const url = redirectToWithAsmCookie(returnTo)
    const utmQueryString = constructUtmParamQueryString(searchParams)
    if (utmQueryString.length > 0) {
        // Construct the URL with the UTM params appended
        const utmSearchParams = new URLSearchParams(removePrefix(utmQueryString, '?'))
        utmSearchParams.forEach((value, key) => {
            url.searchParams.append(key, value)
        })
    }
    safelyNavigate(url, true)
}

export const redirectToWithAsmCookie = (returnTo: string | null): URL => {
    if (!REDIRECT_THROUGH_SEARCH) {
        // If we are not redirecting through search, just return the returnTo URL
        const url = new URL(returnTo || defaultReturnTo)
        return url
    }

    let queryString = ''
    if (returnTo !== null) {
        queryString =
            '?' +
            new URLSearchParams({
                return_to: returnTo, // eslint-disable-line camelcase
            }).toString()
    }
    const url = new URL(
        `${window.location.protocol}//${SEARCH_DOMAIN}${SEARCH_REDIRECT_ENDPOINT}${queryString}`
    )
    return url
}

function removePrefix(inputString: string, prefix: string): string {
    if (inputString.startsWith(prefix)) {
        return inputString.slice(prefix.length)
    }
    return inputString // If the prefix is not found, return the original string
}

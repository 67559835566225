import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { LAUNCHDARKLY_CLIENT_ID, PENDO_API_KEY } from './config'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import ReactGA from 'react-ga4'

ReactGA.initialize('G-1X9VFRM6DQ')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
;(async () => {
    const LDProvider = await asyncWithLDProvider({
        clientSideID: LAUNCHDARKLY_CLIENT_ID,
        options: { streaming: true },
    })

    root.render(
        <React.StrictMode>
            <LDProvider>
                <App />
                {PENDO_API_KEY && (
                    <script
                        dangerouslySetInnerHTML={{
                            __html: `
                  (function(apiKey){
                      (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
                      v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
                          o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                          y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
                          z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
                  })('${PENDO_API_KEY}');
              `,
                        }}
                    />
                )}
            </LDProvider>
        </React.StrictMode>
    )
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

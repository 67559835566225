import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'

interface PasswordShowAdornmentProps {
    callback: (showPassword: boolean) => void
}

const PasswordShowAdornment: FC<any> = (props: PasswordShowAdornmentProps): ReactElement => {
    const [showPassword, setShowPassword] = useState<boolean>(false)

    const togglePasswordVisibility = () => setShowPassword((prevState: boolean) => !prevState)

    useEffect(() => {
        if (props.callback) {
            props.callback(showPassword)
        }
    }, [showPassword])

    return (
        <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={togglePasswordVisibility}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    )
}

export default PasswordShowAdornment

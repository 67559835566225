import React, { FC, MutableRefObject, ReactElement, RefObject, createRef } from 'react'
import { FormLabel } from '../styled/styles'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { InputMessage } from '../helpers/handlers'
import { BaseValidator, KeysOfType } from '../formValidation/baseValidator'
import { HCAPTCHA_SITE_KEY } from '../config'

export interface CaptchaInputProps {
    id: string
    innerRef?: MutableRefObject<HCaptcha | undefined>
    setCaptcha: (value: string | undefined) => void
    message: string | undefined
}

const CaptchaInput: FC<CaptchaInputProps> = (props: CaptchaInputProps): ReactElement => {
    const onError = (err: any) => {
        console.error('Got a captcha error!', err)
        props.setCaptcha(undefined)
    }

    const onVerify = (token: string, eKey: string) => {
        props.setCaptcha(token)
    }

    return (
        <>
            <FormLabel>Captcha</FormLabel>
            <HCaptcha
                ref={props.innerRef as RefObject<HCaptcha>}
                sitekey={HCAPTCHA_SITE_KEY}
                onVerify={onVerify}
                onError={onError}
            />
            <InputMessage dataTestId={`${props.id}-message`} message={props.message} />
        </>
    )
}

export default CaptchaInput

export function onCaptchaUpdateForm<T, F extends KeysOfType<T, V>, V extends string & T[F]>(
    field: F,
    form: BaseValidator<T>,
    setForm: React.Dispatch<React.SetStateAction<BaseValidator<T>>>
): any {
    return (value: string | undefined) => {
        if (value !== undefined) {
            setForm(form.set(field, value as V))
        }
    }
}

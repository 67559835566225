import { BaseValidator, Messages } from './baseValidator'
import { z } from 'zod'
import { MAX_NAME_PART_LENGTH, messageTooLong, MESSAGE_REQUIRED } from './constants'

const MAX_PAT_NAME_LENGTH = 50

export const nameField = z
    .string()
    .trim()
    .min(1, { message: MESSAGE_REQUIRED })
    .max(MAX_PAT_NAME_LENGTH, { message: messageTooLong(MAX_PAT_NAME_LENGTH) })

export const descriptionField = z
    .string()
    .trim()
    .max(MAX_NAME_PART_LENGTH, { message: messageTooLong(MAX_NAME_PART_LENGTH) })
    .transform((val: string): string | null => (val === '' ? null : val))
    .nullable()

const CreatePATFormSchema = z.object({
    name: nameField,
    description: descriptionField,
})

export type CreatePATFormData = z.infer<typeof CreatePATFormSchema>

class CreatePATFormValidator extends BaseValidator<CreatePATFormData> {
    schema(): any {
        return CreatePATFormSchema
    }

    new(
        initial: CreatePATFormData,
        messages: Messages<CreatePATFormData> = {} as Messages<CreatePATFormData>
    ): CreatePATFormValidator {
        return new CreatePATFormValidator(initial, messages)
    }
}

const defaultFormValues: CreatePATFormData = {
    name: '',
    description: null,
}

export const initCreatePATForm = (initValues: Partial<CreatePATFormData> = {}) => {
    return new CreatePATFormValidator({
        ...defaultFormValues,
        ...initValues,
    })
}

import { z } from 'zod'
import { BaseValidator, Messages } from './baseValidator'
import { MESSAGE_MUST_MATCH, MAX_FULL_NAME_LENGTH, MESSAGE_SUM_NAME_TOO_LONG } from './constants'
import {
    captchaTokenField,
    csrfTokenField,
    emailField,
    nameField,
    organizationField,
    setPasswordField,
    phoneField,
    termsOfServiceField,
} from './fields'

const RegistrationFormSchema = z
    .object({
        firstName: nameField,
        lastName: nameField,
        organization: organizationField,
        email: emailField,
        phone: phoneField,
        tos: termsOfServiceField,
        password: setPasswordField,
        confirmPassword: setPasswordField,
        csrfToken: csrfTokenField,
        captchaToken: captchaTokenField,
    })
    // TODO: This superRefine check on the MAX_FULL_NAME_LENGTH can be removed
    // once accounts are no longer backfilled into the search database
    .superRefine((val, ctx) => {
        if (val.firstName.length + val.lastName.length >= MAX_FULL_NAME_LENGTH) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['firstName'],
                message: MESSAGE_SUM_NAME_TOO_LONG,
            })
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['lastName'],
                message: MESSAGE_SUM_NAME_TOO_LONG,
            })
        }
    })
    .refine((val) => val.password === val.confirmPassword, {
        path: ['confirmPassword'],
        message: MESSAGE_MUST_MATCH,
    })

export type RegistrationFormData = z.infer<typeof RegistrationFormSchema>

class RegistrationValidator extends BaseValidator<RegistrationFormData> {
    schema(): any {
        return RegistrationFormSchema
    }

    new(
        initial: RegistrationFormData,
        messages: Messages<RegistrationFormData> = {} as Messages<RegistrationFormData>
    ): RegistrationValidator {
        return new RegistrationValidator(initial, messages)
    }
}

const defaultRegistrationValues: RegistrationFormData = {
    firstName: '',
    lastName: '',
    organization: '',
    email: '',
    phone: '',
    tos: 'false',
    password: '',
    confirmPassword: '',
    csrfToken: '',
    captchaToken: '',
}

export const initRegistrationForm = (initValues: Partial<RegistrationFormData> = {}) => {
    return new RegistrationValidator({
        ...defaultRegistrationValues,
        ...initValues,
    })
}

import React from 'react'
import { AxiosError } from 'axios'
import {
    parseUIErrors,
    InvalidRecoveryTokenError,
    AccountLinkingInfo,
    AccountUnverifiedError,
    InvalidPasswordError,
} from './kratos'
import { BannerMessage, newErrorBanner, newInfoBanner } from '../components/BannerMessage'
import { newKratosSdk } from '../services/kratos'
import { URLSearchParamsInit } from 'react-router-dom'
import {
    FrontendApiGetLoginFlowRequest,
    FrontendApiGetRecoveryFlowRequest,
    FrontendApiGetVerificationFlowRequest,
    LoginFlow,
    UiText,
} from '@ory/client'
import { Product, ProductEnum } from '../components/ProductSelector'
import { parseAndValidateReturnTo } from './getReturnTo'

export const checkRecoveryFlow = (
    flowId: string,
    setBanner: React.Dispatch<React.SetStateAction<BannerMessage | undefined>>,
    searchParams: URLSearchParams,
    setSearchParams: (
        nextInit: URLSearchParamsInit,
        navigateOptions?:
            | {
                  replace?: boolean | undefined
                  state?: any
              }
            | undefined
    ) => void
) => {
    const req: FrontendApiGetRecoveryFlowRequest = {
        id: flowId,
    }

    newKratosSdk()
        .getRecoveryFlow(req)
        .then((resp) => {
            const { data: recoveryFlow } = resp
            setBanner(getBannerMessage(parseUIErrors(recoveryFlow)))
        })
        .catch((err: AxiosError) => {
            switch (err.response?.status) {
                case 410:
                    console.debug('Expired recovery flow')
                    searchParams.delete('flow')
                    setSearchParams(searchParams)
                    break
                case 403:
                    console.debug('CSRF issue when fetching verification flow')
                    break
                case 404:
                    console.debug('Flow not found')
                    break
                default:
                    console.error(err)
            }
        })
}

// FIXME: After upgrading Kratos to a new version and no longer using the verification links
// we could remove these checks to verify the user through the account recovery process.
export const verificationLinkExpiredMessage =
    'The verification token is invalid or has already been used. Please retry the flow.'
export const verificationLinkExpiredMessageId = 4070001
export const updatedVerificationLinkExpiredMessage =
    'The verification token is invalid or has already been used. Please attempt to verify the account using the account recovery process at ' +
    `${window.location.protocol}//${window.location.hostname}/recover-account`

export const checkVerificationFlow = (
    flowId: string,
    setBanner: React.Dispatch<React.SetStateAction<BannerMessage | undefined>>,
    searchParams: URLSearchParams,
    setSearchParams: (
        nextInit: URLSearchParamsInit,
        navigateOptions?:
            | {
                  replace?: boolean | undefined
                  state?: any
              }
            | undefined
    ) => void
) => {
    const req: FrontendApiGetVerificationFlowRequest = {
        id: flowId,
    }

    newKratosSdk()
        // Include UTM params if they exist...
        .getVerificationFlow(req, { params: searchParams })
        .then((resp) => {
            const { data: verificationFlow } = resp
            setBanner(getBannerMessage(verificationFlow.ui.messages))

            // Follow the `redirect_to` if it is present -- this will be set
            // from the originating `registration` flow
            if (
                verificationFlow.return_to !== undefined &&
                !window.location.href.includes(verificationFlow.return_to)
            ) {
                // Add the flow to the url to present the message after the
                // redirect as well
                const returnToUrl = new URL(verificationFlow.return_to)
                returnToUrl.searchParams.append('flow', flowId)
                window.location.replace(returnToUrl)
            }
        })
        .catch((err: AxiosError) => {
            switch (err.response?.status) {
                case 410:
                    console.debug('Expired recovery flow')
                    searchParams.delete('flow')
                    setSearchParams(searchParams)
                    break
                case 403:
                    console.debug('CSRF issue when fetching verification flow')
                    break
                case 404:
                    console.debug('Flow not found')
                    break
                default:
                    console.error(err)
            }
        })
}

export const linkAccountsMessage =
    'An account with the same email address already exists. Log in with your password to link your account.'

export async function getLoginFlow(
    flowId: string,
    setBanner: React.Dispatch<React.SetStateAction<BannerMessage | undefined>>,
    searchParams: URLSearchParams,
    setSearchParams: (
        nextInit: URLSearchParamsInit,
        navigateOptions?:
            | {
                  replace?: boolean | undefined
                  state?: any
              }
            | undefined
    ) => void,
    setRedirectUrl: React.Dispatch<React.SetStateAction<URL | null | undefined>>,
    setFlow: React.Dispatch<React.SetStateAction<LoginFlow | undefined>>
) {
    const req: FrontendApiGetLoginFlowRequest = {
        id: flowId,
    }

    return (
        newKratosSdk()
            // Include UTM params if they exist...
            .getLoginFlow(req, { params: searchParams })
            .then((resp) => {
                const { data: loginFlow } = resp
                setBanner(getBannerMessage(loginFlow.ui.messages))
                setFlow(loginFlow)

                if (loginFlow.return_to !== undefined) {
                    // Validate the return to URL
                    const returnTo = parseAndValidateReturnTo(loginFlow.return_to)
                    if (returnTo !== null) {
                        setRedirectUrl(returnTo)
                        searchParams.set('return_to', loginFlow.return_to)
                        setSearchParams(searchParams)
                        return
                    }
                }

                // If the return_to URL is invalid or unset, redirect to the Search app
                setRedirectUrl(Product.getDomainUrl(ProductEnum.Search))
                searchParams.set('return_to', Product.getDomainUrl(ProductEnum.Search).toString())
                setSearchParams(searchParams)
            })
            .catch((err: AxiosError) => {
                searchParams.delete('flow')
                searchParams.delete('no_org_ui')
                setSearchParams(searchParams)
                setFlow(undefined)
                setRedirectUrl(null)
                return handleFlowError(setBanner)(err)
            })
    )
}

const accountUnverifiedMessage = 'Error! Account has not been verified. Please check your email.'
const invalidPasswordMessage = 'Error! Invalid email or password.'

// This function is used to get a banner message from the UI messages returned
// by the Kratos API.
export function getBannerMessage(messages: UiText[] | undefined): BannerMessage | undefined {
    if (messages === undefined || messages.length === 0) {
        return undefined
    }
    for (const message of messages) {
        switch (message.id) {
            case InvalidRecoveryTokenError:
                return newErrorBanner(message.text)
            case verificationLinkExpiredMessageId:
                return newErrorBanner(updatedVerificationLinkExpiredMessage)
            case AccountLinkingInfo:
                return newInfoBanner(linkAccountsMessage)
            case AccountUnverifiedError:
                return newErrorBanner(accountUnverifiedMessage)
            case InvalidPasswordError:
                return newErrorBanner(invalidPasswordMessage)
        }
    }
    return { message: messages[0].text, type: messages[0].type }
}

export const defaultErrorMessage =
    'Error! An error has occurred. If this problem persists, please contact us at support@censys.io.'

// This function is used to handle errors from the Kratos API. It is used in the catch block of
// the Axios promise returned by the Kratos API calls.
// The function checks the error response and sets the banner message accordingly.
// Reference: https://github.com/ory/kratos-selfservice-ui-react-nextjs/blob/master/pkg/errors.tsx
export function handleFlowError(
    setBanner: React.Dispatch<React.SetStateAction<BannerMessage | undefined>>
) {
    return (err: AxiosError<any, any>) => {
        switch (err.response?.data.error?.id) {
            case 'session_inactive':
                setBanner(newErrorBanner('Error! Session is inactive. Please try again.'))
                return
            case 'session_aal2_required':
                setBanner(newErrorBanner('Error! AAL2 authentication required.'))
                return
            case 'session_refresh_required':
                window.location.href = err.response?.data.redirect_browser_to
                return
            case 'self_service_flow_return_to_forbidden':
                setBanner(newErrorBanner('The return_to address is not allowed.'))
                return
            case 'self_service_flow_expired':
                setBanner(newErrorBanner('Error! Login expired. Please try again.'))
                return
            case 'security_csrf_violation':
                setBanner(newErrorBanner('CSRF token mismatch. Please try again.'))
                return
            case 'security_identity_mismatch':
                setBanner(newErrorBanner('Error! Identity mismatch. Please try again.'))
                return
            case 'browser_location_change_required':
                window.location.href = err.response.data.redirect_browser_to
                return
        }

        if (err.response?.data.ui?.nodes?.length) {
            for (const node of err.response.data.ui.nodes) {
                switch (node.attributes?.name) {
                    case 'password_webhook_handler':
                        setBanner(getBannerMessage(node.messages))
                        return
                    case 'saml_webhook_handler':
                        setBanner(getBannerMessage(node.messages))
                        return
                }
            }
        }

        return Promise.reject(err)
    }
}

import { z } from 'zod'

export type Messages<T> = Record<keyof T, string>

// Gnarly key value introspection type
// https://stackoverflow.com/questions/49752151/typescript-keyof-returning-specific-type/49752227#49752227
// TODO: Describe what this is doing a little better
export type KeysOfType<T, V> = keyof {
    [P in keyof T as T[P] extends V ? P : never]: any
}

export abstract class BaseValidator<T> {
    messages: Messages<T>
    values: T

    constructor(initial: T, messages: Messages<T> = {} as Messages<T>) {
        this.messages = messages
        this.values = initial
    }

    // This looks like a cop-out datatype, but it's actually the type implemented
    // by the `z.object` typing, which is generally the schema we'll be using here
    abstract schema(): z.ZodEffects<z.ZodObject<any>> | z.ZodObject<any>

    abstract new(initial: T, messages: Messages<T>): BaseValidator<T>

    validate(): BaseValidator<T> {
        // safeParse will mutate the values if there are any transform rules in the
        // schema. The resulting mutations will be in the validationErrors object at
        // the `data` key, if there were no errors. More info about `safeParse` here:
        // https://github.com/colinhacks/zod#safeparse
        const validationErrors = this.schema().safeParse(this.values)
        this.messages = {} as Messages<T>

        // Clear all errors and return early if validation was successful
        if (validationErrors.success) {
            return this.new(validationErrors.data as T, this.messages)
        }

        // Get each of the errors and insert them into the messages object
        validationErrors.error.issues.forEach((issue: z.ZodIssue) => {
            // Technically the issue path can be a number if an item in a list
            // had an error, but our form data types don't includes lists right now,
            // so this branch should never be hit. But this guard is here Just In Case™
            if (typeof issue.path === 'number') {
                return
            }

            // the `issue.path` is an array because you can have nested objects within
            // your datastructre being validated. The datastructures for our forms are
            // shallow and don't contain any lists or objects, so we won't have a path
            // of more than a single item and can therefore assume the first/only item
            // in this array is the key of our form.
            const field = issue.path[0] as KeysOfType<T, any>

            // Insert the message only if we don't already have an error for that field
            if (this.messages[field] === undefined) {
                this.messages[field] = issue.message
            }
        })
        return this.new(this.values, this.messages)
    }

    validateField(field: keyof T): BaseValidator<T> {
        const validationErrors = this.schema().safeParse(this.values)

        // Clear all errors and return early if validation was successful
        if (validationErrors.success) {
            this.messages = {} as Messages<T>
            return this.new(this.values, this.messages)
        }

        // Find the error for our field if it's present and add it to the messages
        const fieldIssue = validationErrors.error.issues
            .filter((issue: z.ZodIssue) => issue.path[0] === field)
            .at(0)
        if (fieldIssue === undefined) {
            // There were no issues for the field. Clear any existing message for this field from the form
            //
            // In the context of the app, `field` is never a user provided value, and is hardcoded in the props
            // of components that use this validator. Because of that, it's safe to ignore semgrep security
            // warnings about code injection
            // nosemgrep: gitlab.eslint.detect-object-injection
            delete this.messages[field]
        } else {
            // There was an issue for this field. Add the message for this field to the form
            //
            // In the context of the app, `field` is never a user provided value, and is hardcoded in the props
            // of components that use this validator. Because of that, it's safe to ignore semgrep security
            // warnings about code injection
            // nosemgrep: gitlab.eslint.detect-object-injection
            this.messages[field] = fieldIssue.message
        }
        return this.new(this.values, this.messages)
    }

    set<V extends T[KeysOfType<T, V>]>(
        field: KeysOfType<T, V>,
        value: V,
        validate: boolean = true
    ): BaseValidator<T> {
        // In the context of the app, `field` is never a user provided value, and is hardcoded in the props
        // of components that use this validator. Because of that, it's safe to ignore semgrep security
        // warnings about code injection
        // nosemgrep: gitlab.eslint.detect-object-injection
        this.values[field] = value
        if (validate) {
            return this.validateField(field)
        } else {
            return this.new(this.values, this.messages)
        }
    }

    // Yes this function looks pretty stupid, but we create a temporary instance
    // since the `validate` function mutates the state of the current object, and
    // we want to avoid doing that since the `isValid` function should not have
    // side effects. So create a new instance, validate it, then check if there
    // any error messages
    isValid(): boolean {
        const dummyFormInst = this.new(this.values, this.messages)
        const _ = dummyFormInst.validate()
        return Object.keys(dummyFormInst.messages).length === 0
    }
}

import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
    typography: {
        fontFamily: `"Inter", sans-serif`,
    },
    palette: {
        background: {
            default: '#f6f7f9',
        },
        primary: {
            light: '#63b8ff',
            main: '#0989e3',
            dark: '#005db0',
            contrastText: '#000',
        },
        secondary: {
            main: '#4db6ac',
            light: '#82e9de',
            dark: '#00867d',
            contrastText: '#000',
        },
    },
    components: {
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#000',
                    fontSize: '14px',
                    lineHeight: '1.45',
                },
            },
        },
        MuiCircularProgress: {
            variants: [
                {
                    props: { color: 'primary' },
                    style: {
                        color: '#ff7a38',
                    },
                },
            ],
        },
        MuiTextField: {
            variants: [
                {
                    props: { color: 'primary' },
                    style: {
                        marginTop: '5px',
                        fontSize: '14px',
                        fontFamily: `'InterBold', serif`,
                        color: '#1b1d1f',
                        border: 'none',
                        borderRadius: '4px',
                        boxShadow: 'none',
                        backgroundColor: '#f6f7f9',
                        width: '100%',
                        padding: '10px 20px',
                        textUnderlinePosition: 'unset',
                    },
                },
            ],
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: `"InterBold", sans-serif`,
                    padding: '14px 36px',
                    textTransform: 'capitalize',
                    borderRadius: '5px',
                    fontSize: '16px',
                    marginTop: '8px',
                    marginBotton: '6px',
                },
            },
            variants: [
                {
                    props: { color: 'primary' },
                    style: {
                        background: '#ff7a38',
                        border: '1px solid #ef6622',
                        color: '#fff',
                        ':hover': {
                            background: '#ef6622',
                        },
                    },
                },
                {
                    props: { color: 'secondary' },
                    style: {
                        backgroundColor: 'white',
                        border: '#c1c5cb 1px solid',
                        color: '#18181c',
                        boxShadow: 'none',
                        ':hover': {
                            backgroundColor: '#f6f7f9',
                            border: '#252B36 1px solid',
                            color: '#252B36',
                        },
                    },
                },
            ],
        },
    },
})

export default theme

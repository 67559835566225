import styled from 'styled-components'

export const headerStyle = {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: `"InterBold", serif`,
}

export const mainStyle = {
    flexGrow: 1,
}

export const loginRegisterBlockStyle = {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'left',
    textAlign: 'left',
    padding: '40px 40px 40px 40px',
    margin: 'auto',
    marginBottom: '30px',
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 3%)',
    borderRadius: '2px',
}

export const ThemeLink = styled.a`
    color: '#337ab7';
    text-decoration: none;
    font-family: 'Inter', sans-serif;
`

export const PlainLink = styled.a`
    color: inherit;
`

export const FormLink = styled.a`
    color: #70777f;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
    text-decoration: underline;
`

export const FormLabel = styled.label`
    font-size: 0.85em;
    line-height: 1.45;
    font-family: Inter, sans-serif;
`

export const ProductSelectorStyle = styled.label`
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 16px 20px;
    border: 1px solid #e1e3e5;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        background-color: #f3f5ff;
    }
`

export const unstyledLink = {
    textDecoration: 'none',
    color: 'inherit',
}

export const selectedProduct = {
    color: '#385cff',
    borderColor: '#385cff',
    backgroundColor: '#f3f5ff',
}

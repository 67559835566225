import { z } from 'zod'
import { isValidPhoneNumber } from 'react-phone-number-input'
import {
    MAX_EMAIL_LENGTH,
    MAX_NAME_PART_LENGTH,
    MAX_ORGANIZATION_LENGTH,
    MAX_PASSWORD_LENGTH,
    messageTooLong,
    MESSAGE_CAPTCHA,
    MESSAGE_INVALID_EMAIL,
    MESSAGE_INVALID_PHONE,
    MESSAGE_REQUIRED,
    MESSAGE_TOS,
    MESSAGE_SHORT_PASSWORD,
    MESSAGE_SIMPLE_PASSWORD,
} from './constants'

const countStrength = (value: string): number => {
    let charTypes = 0
    if (value.match(/[A-Z]/)) {
        charTypes += 1
    }
    if (value.match(/[a-z]/)) {
        charTypes += 1
    }
    if (value.match(/[0-9]/)) {
        charTypes += 1
    }
    if (value.match(/[~!@#$%^&*()_+{}":;\[\]']/)) {
        charTypes += 1
    }
    return charTypes
}

const zodValidatePassword = (value: string, ctx: z.RefinementCtx) => {
    // Check how many types of characters were provided. The password is considered
    // insecure if less than 3 types were provided.
    if (countStrength(value) < 3) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: MESSAGE_SIMPLE_PASSWORD,
        })
        return
    }

    // We indicate that validation is successful by not attaching an
    // issue to the context, so it's safe to simply return nothing here.
    return
}

// Used for cases where the user is setting a new password
export const setPasswordField = z
    .string()
    .trim()
    .min(8, {
        message: MESSAGE_SHORT_PASSWORD,
    })
    .max(MAX_PASSWORD_LENGTH, {
        message: messageTooLong(MAX_PASSWORD_LENGTH),
    })
    .superRefine(zodValidatePassword)

// Used for cases where the user is providing their existing password
export const providePasswordField = z
    .string()
    .trim()
    .min(1, {
        message: MESSAGE_REQUIRED,
    })
    .max(MAX_PASSWORD_LENGTH, {
        message: messageTooLong(MAX_PASSWORD_LENGTH),
    })

export const emailField = z
    .string()
    .trim()
    .min(1, { message: MESSAGE_REQUIRED })
    .max(MAX_EMAIL_LENGTH, { message: messageTooLong(MAX_EMAIL_LENGTH) })
    .email({ message: MESSAGE_INVALID_EMAIL })

export const csrfTokenField = z.string()

export const captchaTokenField = z.string().min(1, { message: MESSAGE_CAPTCHA })

export const nameField = z
    .string()
    .trim()
    .min(1, { message: MESSAGE_REQUIRED })
    .max(MAX_NAME_PART_LENGTH, { message: messageTooLong(MAX_NAME_PART_LENGTH) })

export const organizationField = z
    .string()
    .trim()
    .min(1, { message: MESSAGE_REQUIRED })
    .max(MAX_ORGANIZATION_LENGTH, { message: messageTooLong(MAX_ORGANIZATION_LENGTH) })

export const phoneField = z
    .string()
    .nullable()
    .refine((value: string | null) => value === null || value === '' || isValidPhoneNumber(value), {
        message: MESSAGE_INVALID_PHONE,
    })

export const termsOfServiceField = z.string().regex(/true/, { message: MESSAGE_TOS })

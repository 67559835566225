import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { newKratosSdk } from '../services/kratos'
import { AxiosError } from 'axios'
import { LoginFlow, UiText, UpdateLoginFlowBody } from '@ory/client'
import { AuthContext } from './AuthProvider'
import { FormLink, unstyledLink } from '../styled/styles'
import { BannerMessage, newSuccessBanner, newErrorBanner } from './BannerMessage'
import {
    handleEnterPress,
    TextFormField,
    onBlurUpdateForm,
    onInputUpdateForm,
} from '../helpers/handlers'
import PasswordShowAdornment from './PasswordShowAdornment'
import LoadingBackdrop from './LoadingBackdrop'
import { AccountLinkingInfo, csrfFromFlow } from '../helpers/kratos'
import { constructUtmParamQueryString } from '../helpers/utm'
import { initLoginForm } from '../formValidation/formLogin'
import { LoginAuthnMethod, newAccountsApiUiV1 } from '../services/accounts'
import {
    defaultErrorMessage,
    getBannerMessage,
    handleFlowError,
} from '../helpers/kratosFlowHandler'

enum LoginStage {
    IdentityEmail = 1,
    AuthnPassword,
    AuthnOidc,
}

interface IdentityCardProps {
    email: string
    message: string
    onNext: () => void
    onBack: () => void
    onInput: () => void
    onBlur: () => void
    queryParams: string
}

const IdentityCard = (props: IdentityCardProps): ReactElement => {
    return (
        <>
            <TextFormField
                id="email"
                label="Email Address"
                value={props.email}
                message={props.message}
                required={true}
                autoFocus={true}
                onBlur={props.onBlur}
                onInput={props.onInput}
                onKeyDown={handleEnterPress(props.onNext)}
                InputProps={{
                    placeholder: 'pat@acme.co',
                }}
            />
            <Grid container justifyContent="space-between">
                {/*
                    The back button only lives on the first part of the form where you
                    can choose to go back to the part of the form where you pick which
                    application to authenticate to.
                */}
                <Grid item>
                    <Link to={`/login${props.queryParams}`} style={unstyledLink}>
                        <Button color="secondary" id="back-btn" onClick={props.onBack}>
                            Back
                        </Button>
                    </Link>
                </Grid>
                <Grid item>
                    <Button id="next-btn" color="primary" onClick={props.onNext}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

interface AuthnPasswordCardProps {
    email: string
    password: string
    message: string
    onNext: () => void
    onBack: () => void
    onInput: () => void
    onBlur: () => void
}

const AuthnPasswordCard = (props: AuthnPasswordCardProps): ReactElement => {
    const navigate = useNavigate()

    const [showPassword, setShowPassword] = useState<boolean>(false)

    const handleRecovery = () => {
        if (props.email) {
            const searchString = new URLSearchParams({ email: props.email }).toString()
            navigate(`/recover-account?${searchString}`)
        } else {
            navigate('/recover-account')
        }
    }

    return (
        <>
            <div style={{ marginBottom: '20px' }}>
                <Typography component="p" variant="subtitle1">
                    {props.email}
                </Typography>
                <FormLink onClick={props.onBack}>Different email address?</FormLink>
            </div>

            <div style={{ marginBottom: '20px' }}>
                <TextFormField
                    id="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={props.password}
                    message={props.message}
                    required={true}
                    autoFocus={true}
                    onBlur={props.onBlur}
                    onInput={props.onInput}
                    onKeyDown={handleEnterPress(props.onNext)}
                    margin="normal"
                    InputProps={{
                        placeholder: 'Enter Your Password',
                        endAdornment: <PasswordShowAdornment callback={setShowPassword} />,
                    }}
                />
                <FormLink onClick={handleRecovery}>Forgot password?</FormLink>
            </div>

            <Grid container justifyContent="end">
                <Grid item>
                    <Button color="primary" id="login-btn" onClick={props.onNext}>
                        Log In
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

// The `onNext` method is called as soon as the prop renders
interface AuthnOidcCardProps {
    email: string
    onNext: () => void
}

const AuthnOidcCard = (props: AuthnOidcCardProps): ReactElement => {
    useEffect(() => {
        props.onNext()
    }, [])

    return (
        <>
            <LoadingBackdrop data-testid="login-loading-spinner" loading={true} />
            <TextFormField id="email" label="Email Address" value={props.email} disabled />
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Link to={'/login'} style={unstyledLink}>
                        <Button color="secondary" id="back-btn" disabled>
                            Back
                        </Button>
                    </Link>
                </Grid>
                <Grid item>
                    <Button id="next-btn" color="primary" disabled>
                        Next
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

interface LoginFormProps {
    flow: LoginFlow
    setBanner: React.Dispatch<React.SetStateAction<BannerMessage | undefined>>
    onSuccess: () => void
    onBack: () => void
}

const LoginForm = (props: LoginFormProps): ReactElement => {
    const [stage, setStage] = useState<LoginStage>(LoginStage.IdentityEmail)
    const { getSession } = useContext(AuthContext)
    const [searchParams] = useSearchParams()
    const [form, setForm] = useState(
        initLoginForm({
            email: emailFromLoginFlow(props.flow) ?? searchParams.get('email') ?? '',
        })
    )

    // Keep the UTM query string params to append to the login url
    const utmQueryString = constructUtmParamQueryString(searchParams)

    const validateFormEmail = async () => {
        setForm(form.validateField('email'))

        // Early exit if there are validation messages
        if (form.messages.email) {
            return
        }

        // If the `no_org_ui` query param is set to true, then we skip the
        // login method check because the flow is being used to link an identity
        if (searchParams.get('no_org_ui') === 'true') {
            // The email is already validated, so we can proceed to the next stage
            setStage(LoginStage.AuthnPassword)
            return
        }

        // No messages means the input field was valid.
        // Proceed to check the backend for which login method to use
        // and set the next form stage accordingly
        const loginMethod = await newAccountsApiUiV1().getLoginMethod(form.values.email)
        if (loginMethod.method === LoginAuthnMethod.Password) {
            setStage(LoginStage.AuthnPassword)
            return
        }
        if (loginMethod.method === LoginAuthnMethod.Oidc) {
            setStage(LoginStage.AuthnOidc)
            return
        }

        // Should be unreachable
        console.error('Unsupported login method')
        return
    }

    const handleLoginSubmit = () => {
        const validatedForm = form.validate()
        setForm(validatedForm)

        // If the form isn't valid, just return. We don't need to display
        // a banner or anything here since the `form.validate()` call above
        // will have updated state, thereby causing error messages to be rendered
        // for any invalid input fields
        if (!validatedForm.isValid() && stage !== LoginStage.AuthnOidc) {
            return
        }

        if (props.flow === undefined) {
            console.debug('handleLogin props.flow is undefined')
            return
        }

        let payload: UpdateLoginFlowBody | null = null

        if (stage === LoginStage.AuthnOidc) {
            console.debug('handleLogin proceeding with oidc method')
            /* eslint-disable camelcase */
            payload = {
                method: 'oidc',
                csrf_token: csrfFromFlow(props.flow),
                provider: 'broker',
                upstream_parameters: {
                    login_hint: validatedForm.values.email,
                },
            }
            /* eslint-enable camelcase */
        } else if (stage === LoginStage.AuthnPassword) {
            console.debug('handleLogin proceeding with password method')
            /* eslint-disable camelcase */
            payload = {
                method: 'password',
                csrf_token: csrfFromFlow(props.flow),
                identifier: validatedForm.values.email,
                password: validatedForm.values.password,
            }
            /* eslint-enable camelcase */
        }

        if (payload === null) {
            console.error(`Invalid auth stage: ${stage}`)
            return
        }

        newKratosSdk()
            .updateLoginFlow({
                flow: props.flow.id,
                updateLoginFlowBody: payload,
            })
            .then(({ data }) => {
                getSession()
                props.onSuccess()
            })
            .catch(handleFlowError(props.setBanner))
            .catch((err: AxiosError<any, any>) => {
                switch (err.response?.status) {
                    case 400:
                        const messages = err.response?.data.ui.messages as UiText[]
                        props.setBanner(getBannerMessage(messages))
                        return
                    case 401:
                    case 403:
                        props.setBanner(newErrorBanner('Error! User is not authorized.'))
                        return
                    case 404:
                    case 410:
                        props.setBanner(newErrorBanner('Error! Login expired. Please try again.'))
                        return
                    default:
                        props.setBanner(newErrorBanner(defaultErrorMessage))
                        return
                }
            })
    }

    useEffect(() => {
        // If the user navigates back in the browser then it will remove the `return_to` query param
        // if it had been selected via the product selector. We guard against this by using the callback
        // to go back to the previous part of the login page if this action happens.
        const returnTo = searchParams.get('return_to')
        if (returnTo === null) {
            props.onBack()
        }
    }, [searchParams])

    useEffect(() => {
        if (form.values.email) {
            validateFormEmail()
        }
    }, [])

    const identityEmail =
        stage === LoginStage.IdentityEmail ? (
            <IdentityCard
                email={form.values.email}
                message={form.messages.email}
                onInput={onInputUpdateForm('email', form, setForm)}
                onBlur={onBlurUpdateForm('email', form, setForm)}
                onNext={validateFormEmail}
                onBack={props.onBack}
                queryParams={utmQueryString}
            />
        ) : (
            <></>
        )

    const authnPassword =
        stage === LoginStage.AuthnPassword ? (
            <AuthnPasswordCard
                email={form.values.email}
                password={form.values.password}
                message={form.messages.password}
                onInput={onInputUpdateForm('password', form, setForm)}
                onBlur={onBlurUpdateForm('password', form, setForm)}
                onNext={handleLoginSubmit}
                onBack={() => {
                    initLoginForm({
                        email: emailFromLoginFlow(props.flow) ?? searchParams.get('email') ?? '',
                    })
                    props.setBanner(undefined)
                    setStage(LoginStage.IdentityEmail)
                }}
            />
        ) : (
            <></>
        )

    let authnOidc = <></>
    if (stage === LoginStage.AuthnOidc) {
        authnOidc = <AuthnOidcCard email={form.values.email} onNext={handleLoginSubmit} />
    }

    useEffect(() => {
        if (stage === LoginStage.AuthnOidc) {
            // Set the banner with the redirection success message
            props.setBanner(newSuccessBanner('Redirecting to your SAML configured IdP'))
        }
    }, [stage])

    return (
        <>
            {identityEmail}
            {authnPassword}
            {authnOidc}
        </>
    )
}

interface AccountLinkingContext {
    provider: string
    newLoginUrl: string
    duplicateIdentifier: string
}

const emailFromLoginFlow = (flow: LoginFlow): string | undefined => {
    if (flow === undefined || flow.ui === undefined || flow.ui.messages === undefined) {
        return undefined
    }
    for (const message of flow.ui.messages) {
        // Get the email from the account linking message
        if (message.id === AccountLinkingInfo) {
            if (message.context) {
                const context = message.context as AccountLinkingContext
                if (context.duplicateIdentifier) {
                    return context.duplicateIdentifier
                }
            }
        }
    }
    return undefined
}

export default LoginForm

// This file defines a React Context which keeps track of the authenticated session.

import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { newKratosSdk } from '../services/kratos'
import { Session } from '@ory/client'
import { PRIVILEGED_SESSION_MAX_AGE } from '../config'
import ReactGA from 'react-ga4'

export type SessionState = Session | null

export interface IAuthContext {
    session: SessionState | undefined
    isPrivileged: boolean | undefined
    getSession: () => Promise<SessionState>
}

export const AuthContext = createContext<IAuthContext>({
    session: undefined,
    isPrivileged: undefined,
    getSession: () => {
        return Promise.reject('Error! Encountered default AuthContext.getSession')
    },
})

interface AuthContextProps {
    children: ReactNode
}

const isSessionPrivileged = (session: Session): boolean => {
    if (session.authenticated_at === undefined) {
        return false
    }

    const authorizedAt = Date.parse(session.authenticated_at)
    const secondsOld = (Date.now() - authorizedAt) / 1000
    return secondsOld < PRIVILEGED_SESSION_MAX_AGE
}

export const AuthProvider = ({ children }: AuthContextProps) => {
    const [sessionState, setSessionState] = useState<SessionState | undefined>(undefined)
    const [isPrivileged, setIsPrivileged] = useState<boolean | undefined>(undefined)
    const [sessionLoading, setSessionLoading] = useState<boolean>(false)

    // Use browser stored session cookie to retrieve the user's session data
    const getSession = (): Promise<SessionState> =>
        new Promise<SessionState>((resolve, reject) => {
            newKratosSdk()
                .toSession()
                .then(({ data }) => {
                    setSessionState(data)
                    setSessionLoading(false)
                    resolve(data)

                    // Session was fetched, make sure Google Analytics
                    // is tracking this User.
                    // The User ID is represented without dashes in Search
                    // despite it being returned as a proper UUID here, thus
                    // we strip any dashes.
                    ReactGA.set({ userId: sessionState?.identity?.id.replace('-', '') })

                    return
                })
                .catch((err: AxiosError) => {
                    if (err.response?.status === 401) {
                        setSessionState(null)
                        setSessionLoading(false)
                        resolve(null)
                        return
                    } else {
                        // A network or some other error occurred
                        console.error('Unexpected kratos error while calling whoami:', err)
                    }
                    reject()
                })
        })

    // On first render, start loading the session
    // TODO: This will double call the endpoint until we can move this
    // sessionLoading state into some other parent component
    useEffect(() => {
        if (sessionLoading) {
            return
        }
        setSessionLoading(true)
        getSession()
    }, [])

    useEffect(() => {
        if (sessionState) {
            setIsPrivileged(isSessionPrivileged(sessionState))
        } else {
            setIsPrivileged(undefined)
        }
    }, [sessionState])

    return (
        <AuthContext.Provider
            value={{
                // The session information
                session: sessionState,

                isPrivileged: isPrivileged,

                // Fetches the session from the server
                getSession: getSession,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider

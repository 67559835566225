import React, { FC, ReactElement, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Grid } from '@mui/material'
import Layout, { SmallContainer } from '../Layout'
import { RecoverAccountSubheader } from '../components/Subheader'
import { BannerMessage } from '../components/BannerMessage'

const errorBannerMessage = (message: string): BannerMessage => ({
    type: 'error',
    message,
})

const RecoverAccount: FC<any> = (): ReactElement => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    useEffect(() => {
        // `flow`, `token` and `nonce` are all required by the self-service/recovery endpoint, meaning
        // all three params must be present when this page loads. If any of them are not available, redirect
        // back to the initial recovery page with an error.
        const paramFlow = searchParams.get('flow')
        const paramToken = searchParams.get('token')
        const paramNonce = searchParams.get('nonce')
        if ([paramFlow, paramToken, paramNonce].includes(null)) {
            console.log('navigating away...')
            // Redirect back to /recover-account
            const navState = { state: {} as any }
            navState.state.banner = errorBannerMessage(
                'Could not find reset session. Please restart the recovery process.'
            )
            navigate('/recover-account', navState)
        }
    }, [])

    // Computing this here rather than just directly templating the searchParams avoids including
    // the `?` when there are no query params (which should never happen under typical usage,
    // but ya never know)
    const paramString = searchParams.toString() !== '' ? `?${searchParams.toString()}` : ''

    return (
        <Layout heading="Recover Account" subheader={RecoverAccountSubheader}>
            <SmallContainer>
                <Grid container spacing={3}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <p style={{ textAlign: 'center' }}>
                            {
                                'Thank you for verifying your email. Please click "Continue" to reset your password.'
                            }
                        </p>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            marginBottom: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Button id="reset-btn" href={`/self-service/recovery${paramString}`}>
                            Continue
                        </Button>
                    </Grid>
                </Grid>
            </SmallContainer>
        </Layout>
    )
}

export default RecoverAccount

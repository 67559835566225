import { LoginFlow, UiContainer, UiNode, UiNodeInputAttributes, UiText } from '@ory/client/api'

const ExistingIdentifierError: number = 4000007
const InvalidPasswordError: number = 4000006
const InvalidRecoveryTokenError: number = 4060004
const AccountUnverifiedError: number = 4000010
const FlowExpirationError: number = 4060005

const AccountLinkingInfo: number = 1010016

type UINodeErrorMap = {
    name: String
    messages: Array<UiText>
}

const parseUINodeErrors = (data: any): Array<UINodeErrorMap> => {
    return data.ui.nodes
        .filter((node: UiNode) => node.messages.length > 0)
        .map((node: UiNode) => {
            const attrs = node.attributes as UiNodeInputAttributes
            return {
                name: attrs.name,
                messages: node.messages,
            }
        })
}

const parseUIErrors = (data: any): Array<UiText> => {
    if (data.ui.messages) {
        return data.ui.messages.filter((message: UiText) => message.type === 'error')
    } else {
        return data.ui.nodes.reduce((acc: Array<UiText>, node: UiNode) => {
            const errors = node.messages.filter((message: UiText) => message.type === 'error')
            return acc.concat(errors)
        }, [])
    }
}

const csrfFromUiContainer = (ui: UiContainer): string => {
    const token: string = ui.nodes
        .filter((node: UiNode) => {
            const attributes = node.attributes as UiNodeInputAttributes
            return attributes.name === 'csrf_token'
        })
        .map((node: UiNode) => {
            const attributes = node.attributes as UiNodeInputAttributes
            return attributes.value
        })
        .reduce((current: string, next: string) => (current !== '' ? current : next), '')
    return token
}

const csrfFromFlow = (flow: LoginFlow): string => {
    return csrfFromUiContainer(flow.ui)
}

export {
    csrfFromFlow,
    csrfFromUiContainer,
    parseUIErrors,
    parseUINodeErrors,
    ExistingIdentifierError,
    InvalidPasswordError,
    InvalidRecoveryTokenError,
    AccountUnverifiedError,
    FlowExpirationError,
    AccountLinkingInfo,
}

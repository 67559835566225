import { BannerMessage } from '../components/BannerMessage'

export type LocationState = { [key: string]: any }

interface CallbackType<T> {
    (state: LocationState): T | null
}

// Helper function for determining if the value of location.state from the
// useLocation hook is valid. This function provides runtime type safety.
// Due to limitations in both type narrowing and interface validation at runtime,
// this function is a little longwinded.
export function stateFromUnknown<T>(
    uncleanState: unknown,
    pageStateValidator: CallbackType<T>
): T | null {
    // If state is not an object, then we can't work with it at all,
    // so our state is null
    if (typeof uncleanState !== 'object') {
        return null
    }

    // Null is a valid variant of the Object type, so if our object is null, then
    // our state is also null. This is kinda obvious, but this is necessary for
    // narrowing the type
    if (uncleanState === null) {
        return null
    }

    const state = uncleanState as LocationState

    return pageStateValidator(state)
}

export interface BannerState {
    banner: BannerMessage | undefined
}

export const bannerStateValidator = (locationState: LocationState): BannerState | null => {
    const pageState: BannerState = {
        banner: undefined,
    }

    if (
        typeof locationState.banner === 'object' &&
        typeof locationState.banner.message === 'string' &&
        typeof locationState.banner.type === 'string'
    ) {
        pageState.banner = locationState.banner
    }

    return pageState
}

import React, { ReactElement } from 'react'

import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core'
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css'
// Import the style override
import './overrides.css'

import { Box, IconButton, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface TosModalProps {
    open: boolean
    handleClose: () => void
}

const modalStyle = {
    top: '50%',
    left: '50%',
    position: 'fixed',

    height: '100%',
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

    justifyContent: 'center',
    alignItems: 'center',

    transform: 'translate(-50%, -50%)',
    border: '2px solid #000',
    boxShadow: 24,
}

const pdfContainerStyle = {
    maxWidth: '720px',
    height: '100%',
    width: '100%',
    borderRadius: '8px',
    position: 'relative',
    paddingTop: '3em',
    paddingBottom: '3em',
}

const closeModalButtonStyle = {
    position: 'absolute',
    zIndex: 100,
    right: '2em',
    top: '2.5em',
}

const TosModal = ({ open, handleClose }: TosModalProps): ReactElement => {
    return (
        <Modal
            sx={modalStyle}
            open={open}
            onClose={() => {
                handleClose()
            }}
            aria-labelledby="terms-of-service-modal-title"
            aria-describedby="terms-of-service-modal-description"
            data-testid="terms-of-service-modal"
        >
            <Box sx={pdfContainerStyle}>
                <IconButton sx={closeModalButtonStyle} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                    <Viewer
                        fileUrl="/Censys_Consolidated_EULA_April_29_2024.pdf"
                        defaultScale={SpecialZoomLevel.PageFit}
                    />
                </Worker>
            </Box>
        </Modal>
    )
}

export default TosModal

// Limits

// The user_v1 schema sets the first_name and last_name fields to
// a maximum of 255 characters.
export const MAX_NAME_PART_LENGTH = 255

// The search db name field has a max length of 255 characters.
// The first and last names are joined with a space character
// while backfilling in search, the sum length of the first &
// last name in this form must be no more than 254.
export const MAX_FULL_NAME_LENGTH = 254

export const MAX_EMAIL_LENGTH = 255

export const MAX_ORGANIZATION_LENGTH = 255

export const MAX_PASSWORD_LENGTH = 128

// Messages

export const MESSAGE_REQUIRED = 'This field is required'

export const MESSAGE_SUM_NAME_TOO_LONG = `Maximum combined length of First Name and Last Name is ${MAX_FULL_NAME_LENGTH} characters`

export const MESSAGE_INVALID_EMAIL = 'A valid email address is required'

export const MESSAGE_TOS = 'You must agree to the Censys Terms of Service'

export const MESSAGE_INVALID_PHONE = 'Phone field is optional but must be valid'

export const MESSAGE_CAPTCHA = 'You must complete the Captcha'

export const MESSAGE_MUST_MATCH = 'Passwords do not match'

export const MESSAGE_SHORT_PASSWORD =
    'Insecure password. Your password must be at least 8 characters long.'

export const MESSAGE_SIMPLE_PASSWORD =
    'Insecure password. Your password must contain at least three of the following [lower, upper, number, symbol].'

export const messageTooLong = (length: number) => `Maximum length is ${length} characters`

import React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { routes as appRoutes } from './routes'
import theme from './styled/theme'
import AuthProvider from './components/AuthProvider'

function App() {
    // define theme

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        {appRoutes
                            .filter((route) => route.enabled)
                            .map((route) => (
                                <Route
                                    key={route.key}
                                    path={route.path}
                                    element={<route.component {...route.props} />}
                                />
                            ))}
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </ThemeProvider>
    )
}

export default App

import React, { FC, ReactElement } from 'react'
import { Container } from '@mui/material'
import styled from 'styled-components'

const ErrorBanner = styled.p`
    color: #a94442;
    background-color: #f2dede;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-color: #ebccd1;
    border-radius: 4px;
    width: 100%;
    text-align: center;
`

const SuccessBanner = styled.p`
    color: #3c763d;
    background-color: #dff0d8;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-color: #b2dba1;
    border-radius: 4px;
    width: 100%;
    text-align: center;
`

const InfoBanner = styled.p`
    color: #31708f;
    background-color: #d9edf7;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-color: #bce8f1;
    border-radius: 4px;
    width: 100%;
    text-align: center;
`

const errorBanner = (message: string): ReactElement => {
    return (
        <ErrorBanner className="header-error" data-testid={'err-banner'}>
            {message}
        </ErrorBanner>
    )
}

const successBanner = (message: string): ReactElement => {
    return (
        <SuccessBanner className="header-success" data-testid={'success-banner'}>
            {message}
        </SuccessBanner>
    )
}

const infoBanner = (message: string): ReactElement => {
    return (
        <InfoBanner className="header-info" data-testid={'info-banner'}>
            {message}
        </InfoBanner>
    )
}

export interface BannerMessage {
    message: string
    type: 'error' | 'success' | 'info'
}

export const Banner: FC<BannerMessage> = ({ message, type }: BannerMessage): ReactElement => {
    function getBannerComponent(type: string) {
        switch (type) {
            case 'error':
                return errorBanner(message)
            case 'success':
                return successBanner(message)
            case 'info':
                return infoBanner(message)
            default:
                return errorBanner(message)
        }
    }

    const bannerComponent = getBannerComponent(type)
    return <Container maxWidth="md">{bannerComponent}</Container>
}

export const newErrorBanner = (message: string): BannerMessage => ({
    message,
    type: 'error',
})

export const newSuccessBanner = (message: string): BannerMessage => ({
    message,
    type: 'success',
})

export const newInfoBanner = (message: string): BannerMessage => ({
    message,
    type: 'info',
})

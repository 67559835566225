import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Grid } from '@mui/material'
import {
    VerificationFlow,
    UpdateVerificationFlowWithLinkMethod,
    FrontendApiUpdateVerificationFlowRequest,
} from '@ory/client'
import { AxiosError } from 'axios'
import { AuthContext } from '../components/AuthProvider'
import Layout, { SmallContainer } from '../Layout'
import { newKratosSdk } from '../services/kratos'
import { csrfFromUiContainer } from '../helpers/kratos'
import { TextFormField, onInputUpdateForm, onBlurUpdateForm } from '../helpers/handlers'
import { guardSession, SessionCheck, SessionStateGuard } from '../helpers/sessionGuard'
import { useBannerUpdate } from '../helpers/useBannerUpdate'
import { initVerifyForm } from '../formValidation/formVerify'
import { verificationFlowExpiredBanner } from '../components/BannerMessageTypes'
import { newSuccessBanner } from '../components/BannerMessage'

const Verify = (): ReactElement => {
    const { session } = useContext(AuthContext)
    const [sessionCheck, setSessionCheck] = useState<SessionCheck>(SessionCheck.Unverified)
    const navigate = useNavigate()

    const [banner, setBanner] = useBannerUpdate()
    const [flow, setFlow] = useState<VerificationFlow>()
    const [form, setForm] = useState(initVerifyForm())

    useEffect(
        () =>
            guardSession(session, {
                active: SessionStateGuard.InactiveOnly,
                onMatch: () => {
                    // There's no session so we can proceed with initializing the page
                    setSessionCheck(SessionCheck.Valid)
                },
                onNoMatch: () => {
                    // We have a valid session and shouldn't need to verify an email,
                    // so we redirect to home
                    setSessionCheck(SessionCheck.Invalid)
                    navigate('/')
                },
            }),
        [session]
    )

    useEffect(() => {
        console.debug('[useEffect(sessionCheck)] sessionCheck changed to', sessionCheck)
        if (sessionCheck !== SessionCheck.Valid) {
            return
        }

        // Initialize the verification flow
        newKratosSdk()
            .createBrowserVerificationFlow()
            .then(({ data }) => {
                setFlow(data)
                setForm(form.set('csrfToken', csrfFromUiContainer(data.ui)))
            })
            .catch((err: AxiosError) => {
                switch (err.response?.status) {
                    case 400:
                        console.error('Error when initializing self service verification flow...')
                }
                console.error(err)
            })
    }, [sessionCheck])

    const handleSendVerificationLink = () => {
        const validatedForm = form.validate()
        setForm(validatedForm)

        if (!validatedForm.isValid()) {
            // If the form isn't valid, just return. We don't need to display
            // a banner or anything here since the `form.validate()` call above
            // will have updated state, thereby causing error messages to be rendered
            // for any invalid input fields
            return
        }

        // Clear banner and have it update based on response from submitting the
        // verification flow
        setBanner(undefined)

        const verificationBody: UpdateVerificationFlowWithLinkMethod = {
            csrf_token: validatedForm.values.csrfToken, // eslint-disable-line camelcase
            email: validatedForm.values.email,
            method: 'link',
        }
        const req: FrontendApiUpdateVerificationFlowRequest = {
            flow: flow?.id ?? '',
            updateVerificationFlowBody: verificationBody,
        }
        // On submission, add the flow ID to the URL but do not navigate. This prevents the user losing
        // their data when they reload the page.
        //
        // Notice:
        // From here on we're not using validatedForm here because the form state may
        // have changed since we called form.validate(), and we don't want to override
        // any of those changes by setting the csrf on the stale validatedForm
        newKratosSdk()
            .updateVerificationFlow(req)
            .then(({ data }) => {
                setFlow(data)
                setForm(form.set('csrfToken', csrfFromUiContainer(data.ui)))
                // Get the message from the response
                if (data.ui.messages !== undefined && data.ui.messages.length > 0) {
                    const message = data.ui.messages[0]
                    setBanner(newSuccessBanner(message.text))
                }
            })
            .catch((err: AxiosError<any, any>) => {
                switch (err.response?.status) {
                    case 400:
                        // Status code 400 implies the email validation had an error
                        setFlow(err.response?.data)
                        const csrfToken = csrfFromUiContainer(err.response?.data.ui)
                        setForm(form.set('csrfToken', csrfToken))
                        return
                    case 404:
                        setBanner(verificationFlowExpiredBanner)
                        return
                    default:
                        console.error(err)
                }
            })
    }

    return (
        <Layout heading="Verify Your Account" banner={banner}>
            <SmallContainer>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextFormField
                            id="email"
                            label="Email Address"
                            value={form.values.email}
                            message={form.messages.email}
                            required={true}
                            autoFocus={true}
                            onBlur={onBlurUpdateForm('email', form, setForm)}
                            onInput={onInputUpdateForm('email', form, setForm)}
                            InputProps={{
                                placeholder: 'pat@acme.co',
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}
                    >
                        <Button id="verification-btn" onClick={handleSendVerificationLink}>
                            Resend Verification Link
                        </Button>
                    </Grid>
                </Grid>
            </SmallContainer>
        </Layout>
    )
}

export default Verify

import React, { FC, ReactElement } from 'react'
import styled from 'styled-components'
import { ENVIRONMENT_LABEL } from '../config'

const BadgeStyle = styled.div`
    position: fixed;
    top: -41px;
    left: -120px;
    padding: 4px 0;
    z-index: 10000;

    transform-origin: top right;
    transform: rotate(-45deg);

    background-color: #fef2de;
    box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.18);
`

const BadgeInnerStyle = styled.div`
    display: inline-block;
    width: 250px;
    color: #445066;
    font-size: 0.8em;
    font-weight: bold;
    letter-spacing: 0.06em;
    text-decoration: none;
    text-align: center;
    line-height: 24px;
`

const EnvironmentBadgePresent: FC = (): ReactElement => {
    return (
        <BadgeStyle>
            <BadgeInnerStyle>Environment: {ENVIRONMENT_LABEL}</BadgeInnerStyle>
        </BadgeStyle>
    )
}

// If the `ENVIRONMENT_LABEL` config is set, then we need to render
// the badge component, but if it's missing, the presumption is that we're
// in production and we shouldn't show any development badge
export const EnvironmentBadge: FC = (): ReactElement =>
    ENVIRONMENT_LABEL ? <EnvironmentBadgePresent /> : <></>

import { BaseValidator, Messages } from './baseValidator'
import { z } from 'zod'
import { emailField, providePasswordField } from './fields'

const LoginFormSchema = z.object({
    email: emailField,
    password: providePasswordField,
})

export type LoginFormData = z.infer<typeof LoginFormSchema>

class LoginFormValidator extends BaseValidator<LoginFormData> {
    schema(): any {
        return LoginFormSchema
    }

    new(
        initial: LoginFormData,
        messages: Messages<LoginFormData> = {} as Messages<LoginFormData>
    ): LoginFormValidator {
        return new LoginFormValidator(initial, messages)
    }
}

const defaultFormValues: LoginFormData = {
    email: '',
    password: '',
}

export const initLoginForm = (initValues: Partial<LoginFormData> = {}) => {
    return new LoginFormValidator({
        ...defaultFormValues,
        ...initValues,
    })
}

const getUtmParams = (searchParams: URLSearchParams): Map<string, string> => {
    // Look for all the related `utm_* params
    // - utm_campaign
    // - utm_content
    // - utm_medium
    // - utm_source
    // - utm_term
    const utmParamMap = new Map<string, string>()
    searchParams.forEach((v, k, _) => {
        if (k.startsWith('utm_')) {
            utmParamMap.set(k, v)
        }
    })

    return utmParamMap
}

const utmParamQueryString = (params: Map<string, string>): string => {
    // If there are no params then just return
    if (params.size === 0) {
        return ''
    }

    const paramString = new URLSearchParams(Object.fromEntries(params)).toString()
    return `?${paramString}`
}

export const constructUtmParamQueryString = (searchParams: URLSearchParams): string => {
    return utmParamQueryString(getUtmParams(searchParams))
}

import React, { FC, ReactElement } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SEARCH_DOMAIN } from '../config'
import CharcoalParagraph from '../styled/Paragraph'
import { PlainLink } from '../styled/styles'
import { constructUtmParamQueryString } from '../helpers/utm'

interface SubheaderProps {
    children: JSX.Element
}

export const Subheader: FC<SubheaderProps> = ({ children }): ReactElement => {
    return <CharcoalParagraph>{children}</CharcoalParagraph>
}

export const DefaultSubheader: JSX.Element = (
    <Subheader>
        <>
            If you have any questions, please contact us at&nbsp;
            <PlainLink href="mailto:support@censys.io">support@censys.io.</PlainLink>
        </>
    </Subheader>
)

interface LoginSubheaderProps {
    sessionAvailable: boolean
}

export const RegistrationSubheader: FC<LoginSubheaderProps> = ({
    sessionAvailable,
}): ReactElement => {
    const [searchParams, _] = useSearchParams()
    const queryString = constructUtmParamQueryString(searchParams)

    const subheaderLink = sessionAvailable
        ? `${window.location.protocol}//${SEARCH_DOMAIN}/account${queryString}`
        : `/login${queryString}`

    return (
        <Subheader>
            <>
                Already have an account?&nbsp;
                <PlainLink href={subheaderLink}>Sign in here!</PlainLink>
            </>
        </Subheader>
    )
}

export const LoginSubheader: FC<LoginSubheaderProps> = ({ sessionAvailable }): ReactElement => {
    // Keep the UTM params on any generated link
    const [searchParams, _] = useSearchParams()
    const queryString = constructUtmParamQueryString(searchParams)

    const subheaderLink = sessionAvailable
        ? `${window.location.protocol}//${SEARCH_DOMAIN}/account${queryString}`
        : `/register${queryString}`
    return (
        <Subheader>
            <>
                Need an account?&nbsp;
                <PlainLink href={subheaderLink}>Sign up for free.</PlainLink>
            </>
        </Subheader>
    )
}

export const RecoverAccountSubheader: JSX.Element = (
    <Subheader>
        <>
            We&apos;ll send you an email with instructions on how to reset your password.
            <br />
            Please <PlainLink href="mailto:support@censys.io">contact us</PlainLink> with any other
            issues.
        </>
    </Subheader>
)

export const NotFoundSubheader: JSX.Element = (
    <Subheader>
        <>
            The requested page could not be found. If you have any questions, please contact us
            at&nbsp;
            <PlainLink href="mailto:support@censys.io">support@censys.io.</PlainLink>
        </>
    </Subheader>
)

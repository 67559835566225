import React, { ReactElement, FC } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { SlideUpTransition } from './transitions'
import CharcoalParagraph from '../../styled/Paragraph'

export type voidClose = () => void
export type dialogClose = (event: any, reason: string) => void

export interface DialogProps {
    open: boolean
    onClose: voidClose | dialogClose
}

interface CenterButtonProps {
    id: string
    text: string
    onClick: () => void
}

export const CenterDialogButton: FC<CenterButtonProps> = (
    props: CenterButtonProps
): ReactElement => {
    return (
        <Grid
            sx={{ paddingTop: '0.5em' }}
            container
            justifyContent="center"
            flex="flex"
            alignItems="center"
        >
            <Grid item xs={8} sm={6} justifyContent="center" display="flex" alignItems="center">
                <Button id={props.id} onClick={props.onClick}>
                    {props.text}
                </Button>
            </Grid>
        </Grid>
    )
}

interface TokenDialogProps extends DialogProps {
    title: string
    subtitle: string
    children: React.ReactNode
    closeButton: boolean
}

export function TokensDialog(props: TokenDialogProps) {
    const { children, closeButton, onClose, open, title, subtitle } = props
    return (
        <Dialog onClose={onClose} open={open} TransitionComponent={SlideUpTransition}>
            <Stack padding="1em">
                <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    {title}
                    {closeButton ? (
                        <IconButton
                            aria-label="close"
                            data-testid={'tokens-dialog-close-btn'}
                            onClick={onClose as voidClose}
                            sx={{
                                position: 'absolute',
                                right: 12,
                                top: 12,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) : (
                        <></>
                    )}
                </DialogTitle>
                <DialogContent>
                    <CharcoalParagraph style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        {subtitle}
                    </CharcoalParagraph>
                    {children}
                </DialogContent>
            </Stack>
        </Dialog>
    )
}

import React, { ReactElement, FC, useEffect, useContext, useState } from 'react'
import { FrontendApiUpdateLogoutFlowRequest, LogoutFlow, UpdateLoginFlowBody } from '@ory/client'
import { useSearchParams } from 'react-router-dom'
import { AxiosError, AxiosResponse } from 'axios'
import { newKratosSdk } from '../services/kratos'
import { AuthContext } from '../components/AuthProvider'
import { guardSession, SessionCheck, SessionStateGuard } from '../helpers/sessionGuard'
import { SEARCH_DOMAIN } from '../config'
import safelyNavigate from '../helpers/safelyNavigate'
import getReturnTo from '../helpers/getReturnTo'

const defaultReturnTo = new URL(`${window.location.protocol}//${SEARCH_DOMAIN}`)

const Logout: FC<any> = (): ReactElement => {
    const { session, getSession } = useContext(AuthContext)
    const [sessionCheck, setSessionCheck] = useState<SessionCheck>(SessionCheck.Unverified)
    const [readyToNavigate, setReadyToNavigate] = useState<boolean>(false)

    const [searchParams] = useSearchParams()

    const refreshSession = () =>
        getSession()
            .then(() => setReadyToNavigate(true))
            .catch((err: AxiosError) => {
                console.error('Failed to update session state')
                console.error(err)
                setReadyToNavigate(true)
            })

    useEffect(
        () =>
            guardSession(session, {
                active: SessionStateGuard.ActiveOnly,
                onMatch: () => {
                    // We have a valid session which can be logged out
                    setSessionCheck(SessionCheck.Valid)
                },
                onNoMatch: () => {
                    // We do not have a valid session, and should redirect the user
                    setSessionCheck(SessionCheck.Invalid)
                    setReadyToNavigate(true)
                },
            }),
        [session]
    )

    useEffect(() => {
        // Initialize login flow only if the session evaluates to invalid
        console.debug('[useEffect(sessionCheck)] sessionCheck changed...', sessionCheck)
        if (sessionCheck !== SessionCheck.Valid) {
            return
        }

        const kratosSDK = newKratosSdk()
        kratosSDK
            .createBrowserLogoutFlow()
            .then((resp: AxiosResponse<LogoutFlow>) => {
                const returnToUrl = getReturnTo(searchParams)
                const returnTo = returnToUrl ? returnToUrl.toString() : undefined
                const payload: FrontendApiUpdateLogoutFlowRequest = {
                    token: resp.data.logout_token,
                    returnTo: returnTo,
                }
                kratosSDK.updateLogoutFlow(payload).then(() => refreshSession())
            })
            .catch((err: AxiosError) => {
                console.error('Kratos error while logging out')
                console.error(err)
                refreshSession()
            })
    }, [sessionCheck])

    useEffect(() => {
        if (!readyToNavigate) {
            return
        }

        // Asserting the value of getReturnTo will not be null beacuse defaultReturnTo
        // will be used if we fail to find the param in searchParams
        safelyNavigate(getReturnTo(searchParams, defaultReturnTo)!)
    }, [readyToNavigate])

    return <></>
}

export default Logout

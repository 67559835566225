import React, { ReactElement, createRef, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Checkbox, FormControlLabel, Grid } from '@mui/material'
import { UiText } from '@ory/client/api'
import { parseUIErrors, parseUINodeErrors } from '../helpers/kratos'
import { newKratosSdk } from '../services/kratos'
import { PlainLink, unstyledLink } from '../styled/styles'
import CaptchaInput, { onCaptchaUpdateForm } from './Captcha'
import { BannerMessage, newErrorBanner } from './BannerMessage'
import {
    PhoneFormField,
    TextFormField,
    InputMessage,
    onInputUpdateForm,
    onPhoneUpdateForm,
    onCheckboxUpdateForm,
    onBlurUpdateForm,
} from '../helpers/handlers'
import PasswordShowAdornment from './PasswordShowAdornment'
import TosModal from './TosModal'
import { AxiosRequestConfig } from 'axios'
import { initRegistrationForm } from '../formValidation/formRegistration'
import { flowErrorBanner, registrationSuccessfulBanner } from './BannerMessageTypes'
import { RegistrationFlow } from '@ory/client'
import { UpdateRegistrationFlowBody } from '../types/ory'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import ReactGA from 'react-ga4'

const formLabelStyle = {
    fontSize: '0.85em',
    fontFamily: `"Inter", serif`,
}

interface RegistrationFormProps {
    flow?: RegistrationFlow
    csrfToken: string
    setBanner: React.Dispatch<React.SetStateAction<BannerMessage | undefined>>
}

const RegisterForm = (props: RegistrationFormProps): ReactElement => {
    const navigate = useNavigate()
    const captchaRef = useRef<HCaptcha>()
    const [showConfirm, setShowConfirm] = useState<boolean>(false)
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [searchParams] = useSearchParams()
    const [showTosModal, setShowTosModal] = useState<boolean>(false)
    const [form, setForm] = useState(
        initRegistrationForm({
            csrfToken: props.csrfToken,
        })
    )

    const handleTosModalClose = () => {
        setShowTosModal(false)
    }

    const handleBack = () => {
        if (window.history.length < 2) {
            navigate('/login')
        } else {
            navigate(-1)
        }
    }

    const handleRegisterSubmit = async () => {
        const validatedForm = form.validate()
        setForm(validatedForm)

        // Flow ID is REQUIRED for submitting the form
        // TODO: Instead of erroring here, just get a new flow ID automatically 🙄
        if (!props.flow) {
            props.setBanner(flowErrorBanner)
            return
        }

        if (!validatedForm.isValid()) {
            // If the form isn't valid, just return. We don't need to display
            // a banner or anything here since the `form.validate()` call above
            // will have updated state, thereby causing error messages to be rendered
            // for any invalid input fields
            return
        }

        const registrationData: {
            method: 'password'
        } & UpdateRegistrationFlowBody = {
            method: 'password',
            // eslint-disable-next-line camelcase
            csrf_token: validatedForm.values.csrfToken,
            password: validatedForm.values.password,
            traits: {
                organization: validatedForm.values.organization,
                email: validatedForm.values.email,
                firstName: validatedForm.values.firstName,
                lastName: validatedForm.values.lastName,
            },
        }

        // If phone field is set then we add the phone value. In the future we could
        // update this to add other `optional` identity attributes
        if (validatedForm.values.phone) {
            registrationData.traits = {
                ...registrationData.traits,
                phone: validatedForm.values.phone,
            }
        }

        await submitRegistration(registrationData, form.values.captchaToken, props.flow.id)
            .then(() => {
                navigate('/login', {
                    state: {
                        banner: registrationSuccessfulBanner,
                    },
                })
            })
            .catch((err) => {
                console.error('Submitting registration failed:', err)
                captchaRef.current?.resetCaptcha()
            })
    }

    useEffect(() => {
        const returnTo = searchParams.get('return_to')
        if (returnTo === null) {
            return
        }
        // Backend validates this value
        if (!returnTo.startsWith(`${window.location.protocol}//`)) {
            console.error('INVALID Return_To')
            return
        }
        window.localStorage.setItem('registration_return_to', JSON.stringify(returnTo))
    }, [searchParams])

    // We need to keep the csrf token up to date
    useEffect(() => {
        setForm(form.set('csrfToken', props.csrfToken))
    }, [props.csrfToken])

    const submitRegistration = async (
        payload: {
            method: 'password'
        } & UpdateRegistrationFlowBody,
        captchaResponse: string,
        flowId: string
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            const options: AxiosRequestConfig = {
                headers: {
                    'X-Captcha-Token': captchaResponse,
                },
            }
            newKratosSdk()
                .updateRegistrationFlow(
                    {
                        flow: flowId,
                        updateRegistrationFlowBody: payload,
                    },
                    options
                )
                .then(() => {
                    resolve()
                    ReactGA.event('new-user-registration')
                })
                .catch((err) => {
                    if (err.response?.status === 410) {
                        if (err.response?.data.error.id === 'self_service_flow_expired') {
                            // This response gives us a new flow to use, so we can attempt to resubmit the
                            // payload using the new flowId
                            const newFlowId = err.response.data.use_flow_id
                            submitRegistration(payload, captchaResponse, newFlowId)
                                .then(resolve)
                                .catch(reject)
                        } else {
                            console.error('Got unexpected error response from kratos 410')
                        }
                    } else {
                        console.debug(
                            'Node Registration Errors:',
                            parseUINodeErrors(err.response?.data)
                        )
                        parseUIErrors(err.response?.data).forEach((message: UiText) => {
                            console.debug('UI Registration Error:', message)
                            props.setBanner(newErrorBanner(message.text))
                        })
                        reject(err)
                    }
                })
        })
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextFormField
                    id="firstName"
                    label="First Name"
                    value={form.values.firstName}
                    message={form.messages.firstName}
                    required={true}
                    requiredLabel={true}
                    autoFocus={true}
                    onBlur={onBlurUpdateForm('firstName', form, setForm)}
                    onInput={onInputUpdateForm('firstName', form, setForm)}
                    InputProps={{
                        placeholder: 'Pat',
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextFormField
                    id="lastName"
                    label="Last Name"
                    value={form.values.lastName}
                    message={form.messages.lastName}
                    required={true}
                    requiredLabel={true}
                    onBlur={onBlurUpdateForm('lastName', form, setForm)}
                    onInput={onInputUpdateForm('lastName', form, setForm)}
                    InputProps={{
                        placeholder: 'Doe',
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextFormField
                    id="organization"
                    label="Organization"
                    value={form.values.organization}
                    message={form.messages.organization}
                    required={true}
                    requiredLabel={true}
                    onBlur={onBlurUpdateForm('organization', form, setForm)}
                    onInput={onInputUpdateForm('organization', form, setForm)}
                    InputProps={{
                        placeholder: 'ACME Co.',
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <PhoneFormField
                    id="phone"
                    label="Phone"
                    value={form.values.phone}
                    message={form.messages.phone}
                    onBlur={onBlurUpdateForm('phone', form, setForm)}
                    onChange={onPhoneUpdateForm('phone', form, setForm)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextFormField
                    id="email"
                    label="Email"
                    value={form.values.email}
                    message={form.messages.email}
                    required={true}
                    requiredLabel={true}
                    onBlur={onBlurUpdateForm('email', form, setForm)}
                    onInput={onInputUpdateForm('email', form, setForm)}
                    InputProps={{
                        placeholder: 'pat@acme.co',
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextFormField
                    id="password"
                    label="Password"
                    value={form.values.password}
                    message={form.messages.password}
                    required={true}
                    requiredLabel={true}
                    onBlur={onBlurUpdateForm('password', form, setForm)}
                    onInput={onInputUpdateForm('password', form, setForm)}
                    InputProps={{
                        type: showPassword ? 'text' : 'password',
                        placeholder: 'Enter Your Password',
                        endAdornment: <PasswordShowAdornment callback={setShowPassword} />,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextFormField
                    id="confirmPassword"
                    label="Confirm Password"
                    value={form.values.confirmPassword}
                    message={form.messages.confirmPassword}
                    required={true}
                    requiredLabel={true}
                    onBlur={onBlurUpdateForm('confirmPassword', form, setForm)}
                    onInput={onInputUpdateForm('confirmPassword', form, setForm)}
                    InputProps={{
                        type: showConfirm ? 'text' : 'password',
                        placeholder: 'Re-Type Your Password',
                        endAdornment: <PasswordShowAdornment callback={setShowConfirm} />,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                {showTosModal && (
                    <TosModal open={showTosModal} handleClose={() => handleTosModalClose()} />
                )}
                <FormControlLabel
                    htmlFor="tos"
                    label={
                        <div style={formLabelStyle}>
                            I agree to the{' '}
                            <PlainLink href="#" onClick={() => setShowTosModal(true)}>
                                Terms of Service
                            </PlainLink>
                        </div>
                    }
                    control={
                        <Checkbox
                            id="tos"
                            data-testid="tos"
                            value={form.values.tos}
                            onChange={onCheckboxUpdateForm('tos', form, setForm)}
                        />
                    }
                />
                <InputMessage dataTestId="tos-message" message={form.messages.tos} />
            </Grid>
            <Grid item xs={12}>
                <CaptchaInput
                    id="captcha"
                    innerRef={captchaRef}
                    setCaptcha={onCaptchaUpdateForm('captchaToken', form, setForm)}
                    message={form.messages.captchaToken}
                />
            </Grid>
            <Grid item xs={6} sx={{ marginBottom: '20px' }}>
                <Link to={'/login'} style={unstyledLink}>
                    <Button color="secondary" id="back-btn" onClick={handleBack}>
                        Back
                    </Button>
                </Link>
            </Grid>
            <Grid item xs={6} sx={{ marginBottom: '20px' }}>
                <Grid container justifyContent="flex-end">
                    <Button id="register-btn" onClick={handleRegisterSubmit}>
                        Create My Account
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default RegisterForm

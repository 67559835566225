import { IdentityStateEnum, Session, SessionAuthenticationMethodMethodEnum } from '@ory/client'

export enum SessionCheck {
    Unverified = 'unverified',
    Valid = 'valid',
    Invalid = 'invalid',
}

export enum SessionStateGuard {
    ActiveOnly,
    InactiveOnly,
}

export interface GuardSessionOptions {
    active: SessionStateGuard
    methods?: SessionAuthenticationMethodMethodEnum[]
    onMatch: () => void
    onNoMatch: () => void
}

interface GuardFacts {
    sessionPresent: boolean | undefined
    sessionActive: boolean | undefined
    sessionMethods: SessionAuthenticationMethodMethodEnum[] | undefined
    identityState: IdentityStateEnum | undefined
}

// Causes navigation side effect if session conditions aren't met
export const guardSession = (session: Session | null | undefined, options: GuardSessionOptions) => {
    console.debug('[guardSession] running session check on ', session)
    const facts: GuardFacts = {
        sessionPresent: undefined,
        sessionActive: undefined,
        sessionMethods: undefined,
        identityState: undefined,
    }

    // If the session object is undefined, then it hasn't been loaded yet and we can't
    // validate anything.
    if (session === undefined) {
        return
    }

    // If the session is null, then we've loaded it from localStorage/whoami and confirmed
    // that we have no session.
    if (session === null) {
        facts.sessionPresent = false
    } else {
        facts.sessionPresent = true
        facts.sessionActive = session.active === true
        facts.sessionMethods = (session.authentication_methods || [])
            .filter((amr) => amr.method !== undefined)
            .map((amr) => amr.method!) // Asserting presence of the method property
        facts.identityState = session.identity?.state
    }

    const active = facts.sessionPresent && facts.sessionActive && facts.identityState === 'active'

    if (options.active === SessionStateGuard.ActiveOnly) {
        if (active && options.methods === undefined) {
            console.debug('[guardSession] calling onMatch for active session')
            options.onMatch()
            return
        }
        if (active && options.methods !== undefined) {
            let matched = false
            options.methods.forEach((authMethod) => {
                if ((facts.sessionMethods || []).includes(authMethod)) {
                    console.debug('[guardSession] calling onMatch for active session w/ method')
                    options.onMatch()
                    matched = true
                    return
                }
            })
            if (!matched) {
                console.debug(
                    '[guardSession] calling onNoMatch for active session w/ incorrect method'
                )
                options.onNoMatch()
            }
            return
        }
        // Reaching this point mean active is falsy, so we call the onNoMatch callback
        console.debug('[guardSession] calling onNoMatch for session not being active')
        options.onNoMatch()
        return
    }

    if (options.active === SessionStateGuard.InactiveOnly) {
        if (active) {
            console.debug('[guardSession] calling onNoMatch for session being active')
            options.onNoMatch()
            return
        }
        console.debug('[guardSession] calling onMatch for session not being active')
        options.onMatch()
        return
    }
}

import React, { FC } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormats from 'dayjs/plugin/localizedFormat'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormats)

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

export interface RelativeTimeDisplayProps {
    prefix: string
    timestamp: string | Dayjs | null
}

export const RelativeTimeDisplay: FC<RelativeTimeDisplayProps> = (
    props: RelativeTimeDisplayProps
) => {
    let title = ''
    let relative = 'never'

    // If the timestamp string is present, then parse it and adjust
    // it for the specified timezone. Then finally prepare the localized
    // long form of the timestamp using the dayjs `localizedFormats`
    // (the `LLLL` format string). Docs on localization here:
    // https://day.js.org/docs/en/display/format#list-of-localized-formats
    if (props.timestamp !== null) {
        const t = dayjs.isDayjs(props.timestamp)
            ? props.timestamp.tz(tz)
            : dayjs(props.timestamp).tz(tz)
        title = t.format('L LLLL')
        relative = dayjs().to(t)
    }

    return <span title={title}>{props.prefix + relative}</span>
}

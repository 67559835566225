import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import { constructUtmParamQueryString } from '../helpers/utm'

import { ProductSelectorStyle, selectedProduct, unstyledLink } from '../styled/styles'
import { ASM_DOMAIN, SEARCH_DOMAIN } from '../config'

// This is a dumb helper function to remove the complexity of variable
// assignment and type narrowing around the try/catch control.
const parseUrl = (urlString: string): URL | null => {
    try {
        return new URL(urlString)
    } catch (err) {
        return null
    }
}

export enum ProductEnum {
    ASM = 'asm',
    Search = 'search',
}

export class Product {
    static PRODUCT_TO_DOMAIN = {
        [ProductEnum.ASM]: ASM_DOMAIN,
        [ProductEnum.Search]: SEARCH_DOMAIN,
    }

    static DOMAIN_TO_PRODUCT = {
        [ASM_DOMAIN]: ProductEnum.ASM,
        [SEARCH_DOMAIN]: ProductEnum.Search,
    }

    public static getProduct(urlString: string | null): ProductEnum | null {
        if (!urlString) {
            return null
        }

        const url: URL | null = parseUrl(urlString)

        // `urlString` and by extension `url.hostname` are values controlled by the user,
        // so we need to avoid accessing objects via index using these values. URL can be null,
        // but `key` can never be null, so an invalid url will still result in this function
        // returning a `null` as expected.
        const domainKey = Object.keys(Product.DOMAIN_TO_PRODUCT).find(
            (key) => key === url?.hostname
        )
        return domainKey === undefined ? null : Product.DOMAIN_TO_PRODUCT[domainKey]
    }

    public static getDomain(product: ProductEnum): string {
        // Product isn't a value that can reasonably be set by a user since the value to
        // use for the `product` variable is decided internally as a result of a button
        // event.
        // nosemgrep: gitlab.eslint.detect-object-injection
        return Product.PRODUCT_TO_DOMAIN[product]
    }

    public static getDomainUrl(product: ProductEnum): URL {
        return new URL(`${window.location.protocol}//${Product.getDomain(product)}`)
    }
}

const createReturnToLoginUrl = (domain: URL, existingParams: string): string => {
    const searchString = new URLSearchParams({
        return_to: domain.toString(), // eslint-disable-line camelcase
    }).toString()

    let loginUrl = `/login?${searchString}`
    if (existingParams.length > 0) {
        // Take the existing params string and strip off the leading `?`
        loginUrl += `&${existingParams.substr(1)}`
    }
    return loginUrl
}

interface ProductSelectorProps {
    setRedirectUrl: React.Dispatch<React.SetStateAction<URL | null | undefined>>
}

type ProductInfo = {
    product: ProductEnum
    title: string
}

const productInfo: Array<ProductInfo> = [
    {
        product: ProductEnum.ASM,
        title: 'Attack Surface Management Platform',
    },
    {
        product: ProductEnum.Search,
        title: 'Censys Search',
    },
]

const ProductSelector = (props: ProductSelectorProps) => {
    const [product, setProduct] = useState<ProductEnum | null>(null)
    const [searchParams] = useSearchParams()

    // Keep the UTM query string params to append to the login url
    const utmQueryString = constructUtmParamQueryString(searchParams)

    // We end up doing this in 2 spots (thru the query params & page state)
    // since we do not progress the login form until this value has changed.
    // Coming back to this it was a little confusing and if there was an area
    // of improvement there could be something that maybe ties into the search
    // params changing that can indicate whether to progress the login rather
    // than having to duplicate this data in 2 places
    useEffect(() => {
        if (product) {
            props.setRedirectUrl(Product.getDomainUrl(product))
        }
    }, [product])

    return (
        <Grid container direction="column" justifyContent="left" sx={{ gap: '16px' }}>
            <Grid item>
                <Typography component="p" variant="subtitle1">
                    Select which Censys product to login to
                </Typography>
            </Grid>
            {productInfo.map((pi, idx) => {
                return (
                    <Grid item key={idx}>
                        <Link
                            style={unstyledLink}
                            to={createReturnToLoginUrl(
                                Product.getDomainUrl(pi.product),
                                utmQueryString
                            )}
                        >
                            <ProductSelectorStyle
                                onClick={() => setProduct(pi.product)}
                                style={product === pi.product ? selectedProduct : {}}
                            >
                                {pi.title}
                            </ProductSelectorStyle>
                        </Link>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default ProductSelector

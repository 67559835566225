import axiosFactory, { AxiosInstance, AxiosResponse } from 'axios'
import dayjs, { Dayjs } from 'dayjs'

const ACCOUNTS_DOMAIN = window.location.hostname

const TOKENS_API_ENDPOINT = '/api/v1/tokens'

export const DEFAULT_ZERO_TIME = '0001-01-01T00:00:00Z'

export interface PatDtoWritable {
    name: string
    description: string | null
}

interface PatListDto {
    tokens: Array<PatDto>
}

export interface PatDto {
    name: string
    description: string
    shortToken: string
    token: string
    createdAt: string
    updatedAt: string
    lastUsedAt: string
}

const translateZeroTime = (timestamp: string): string | null => {
    return timestamp === DEFAULT_ZERO_TIME ? null : timestamp
}

export class PersonalAccessToken {
    name: string
    description: string
    shortToken: string
    createdAt: Dayjs | null
    updatedAt: Dayjs | null
    lastUsedAt: Dayjs | null

    constructor(
        name: string,
        description: string,
        shortToken: string,
        createdAt: string | Dayjs | null,
        updatedAt: string | Dayjs | null,
        lastUsedAt: string | Dayjs | null
    ) {
        this.name = name
        this.description = description
        this.shortToken = shortToken
        this.createdAt = dayjs.isDayjs(createdAt) ? createdAt : this.toDayjs(createdAt)
        this.updatedAt = dayjs.isDayjs(updatedAt) ? updatedAt : this.toDayjs(updatedAt)
        this.lastUsedAt = dayjs.isDayjs(lastUsedAt) ? lastUsedAt : this.toDayjs(lastUsedAt)
    }

    private toDayjs(ts: string | null): Dayjs | null {
        return ts === null || ts === '' ? null : dayjs(ts)
    }

    static fromDto(dto: PatDto): PersonalAccessToken {
        return new PersonalAccessToken(
            dto.name,
            dto.description,
            dto.shortToken,
            translateZeroTime(dto.createdAt),
            translateZeroTime(dto.updatedAt),
            translateZeroTime(dto.lastUsedAt)
        )
    }

    withSecret(token: string): PersonalAccessTokenSecret {
        return new PersonalAccessTokenSecret(
            this.name,
            this.description,
            this.shortToken,
            this.createdAt,
            this.updatedAt,
            this.lastUsedAt,
            token
        )
    }
}

export class PersonalAccessTokenSecret extends PersonalAccessToken {
    token: string

    constructor(
        // Values for PersonalAccessToken
        name: string,
        description: string,
        shortToken: string,
        createdAt: string | Dayjs | null,
        updatedAt: string | Dayjs | null,
        lastUsedAt: string | Dayjs | null,
        // Values for PersonalAccessTokenSecret
        token: string
    ) {
        super(name, description, shortToken, createdAt, updatedAt, lastUsedAt)
        this.token = token
    }

    static fromDto(dto: PatDto): PersonalAccessTokenSecret {
        return PersonalAccessToken.fromDto(dto).withSecret(dto.token)
    }
}

class PersonalAccessTokensApiImpl {
    patHost: string
    axios: AxiosInstance

    constructor(axios: AxiosInstance) {
        this.patHost = ACCOUNTS_DOMAIN
        this.axios = axios
    }

    buildTokensUrl = (kratosUuid: string): string => {
        return `${window.location.protocol}//${this.patHost}${TOKENS_API_ENDPOINT}/${kratosUuid}`
    }

    buildModifyTokenUrl = (kratosUuid: string, shortToken: string): string => {
        return `${window.location.protocol}//${this.patHost}${TOKENS_API_ENDPOINT}/${kratosUuid}/${shortToken}`
    }

    listTokens = (kratosId: string): Promise<Array<PersonalAccessToken>> => {
        const listTokensUrl = this.buildTokensUrl(kratosId)
        return this.axios.get(listTokensUrl).then((response: AxiosResponse<PatListDto>) => {
            return response.data.tokens.map((patDto) => PersonalAccessToken.fromDto(patDto))
        })
    }

    createToken = async (
        kratosId: string,
        writableDto: PatDtoWritable
    ): Promise<PersonalAccessTokenSecret> => {
        const createTokenUrl = this.buildTokensUrl(kratosId)
        return this.axios
            .post(createTokenUrl, writableDto)
            .then((response: AxiosResponse<PatDto>) => {
                return PersonalAccessTokenSecret.fromDto(response.data)
            })
    }

    deleteToken = (kratosId: string, shortToken: string) => {
        const deleteTokenUrl = this.buildModifyTokenUrl(kratosId, shortToken)
        return this.axios.delete(deleteTokenUrl)
    }

    updateToken = (
        kratosId: string,
        shortToken: string,
        writableDto: PatDtoWritable
    ): Promise<PersonalAccessToken> => {
        const updateTokenUrl = this.buildModifyTokenUrl(kratosId, shortToken)
        return this.axios
            .patch(updateTokenUrl, writableDto)
            .then((response: AxiosResponse<PatDto>) => {
                return PersonalAccessToken.fromDto(response.data)
            })
    }
}

export const newPersonalAccessTokensClient = () => {
    const axios = axiosFactory.create()
    axios.defaults.withCredentials = true
    return new PersonalAccessTokensApiImpl(axios)
}

import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, CircularProgress, Grid } from '@mui/material'
import Layout, { SmallContainer } from '../Layout'
import { newErrorBanner, newSuccessBanner } from '../components/BannerMessage'
import { TeamInvitation, newTeamInvitationClient } from '../services/teamInvitations'
import { useBannerUpdate } from '../helpers/useBannerUpdate'
import { AuthContext } from '../components/AuthProvider'
import { SessionCheck, SessionStateGuard, guardSession } from '../helpers/sessionGuard'
import { SEARCH_DOMAIN } from '../config'
import safelyNavigate from '../helpers/safelyNavigate'
import { VerifiableIdentityAddress } from '@ory/client'

const progressStyle = { width: '15%', height: '15%', display: 'flex', justifyContent: 'center' }

interface InvitationDisplayProps {
    invitation: TeamInvitation
}

const InvitationDisplay: FC<InvitationDisplayProps> = ({ invitation }): ReactElement => {
    return (
        <Grid
            item
            xs={12}
            sx={{
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <p style={{ textAlign: 'center' }}>
                {`You have been invited to join a team!`}
                <br />
                {`This invitation will expire in ${invitation.hoursRemaining()} hours.`}
            </p>
        </Grid>
    )
}

const AcceptInvitationGuard: FC<any> = (): ReactElement => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const [banner, setBanner] = useBannerUpdate()
    const { session } = useContext(AuthContext)
    const [sessionCheck, setSessionCheck] = useState<SessionCheck>(SessionCheck.Unverified)
    const [invitationLoading, setLoading] = useState<boolean>(false)
    const [isValid, setValid] = useState<boolean>(false)
    const [invitation, setInvitation] = useState<TeamInvitation | null>(null)

    useEffect(() => {
        guardSession(session, {
            active: SessionStateGuard.ActiveOnly,
            onMatch: () => setSessionCheck(SessionCheck.Valid),
            onNoMatch: () => {
                setSessionCheck(SessionCheck.Invalid)
            },
        })

        getInvitation()
    }, [session])

    // User must be logged in. If they are not, they need to be redirected to the login page
    useEffect(() => {
        if (sessionCheck === SessionCheck.Invalid) {
            const searchString = new URLSearchParams({
                return_to: window.location.toString(), // eslint-disable-line camelcase
            }).toString()
            navigate(`/login?${searchString}`, {
                state: {
                    banner: {
                        message: 'You must be logged in before accepting the invitation',
                        type: 'success',
                    },
                },
            })
        }
        if (sessionCheck !== SessionCheck.Valid) {
            return
        }
    }, [sessionCheck])

    const getInvitation = () => {
        if (session !== undefined && session !== null) {
            const token = searchParams.get('token') ?? ''
            setLoading(true)
            newTeamInvitationClient()
                .getInvitation(token)
                .then((invitation) => {
                    if (invitation.email) {
                        let foundMatch = false
                        session.identity?.verifiable_addresses?.forEach(
                            (addr: VerifiableIdentityAddress) => {
                                if (addr.value.toLowerCase() === invitation.email.toLowerCase()) {
                                    foundMatch = true
                                }
                            }
                        )
                        if (!foundMatch) {
                            setBanner(
                                newErrorBanner(
                                    'Current user email does not match the one linked to this invitation. Please login with the account linked to the email this invitation was sent to.'
                                )
                            )
                        } else {
                            setValid(true)
                            setInvitation(invitation)
                        }
                    } else {
                        console.error('email field missing from invitation')
                    }
                })
                .catch((err) => {
                    if (err.response?.data?.error) {
                        console.error('got error while accepting invitation', err.response.data)
                        setBanner(newErrorBanner(err.response?.data?.error))
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const handleAcceptInvite = () => {
        // Clear banner and have it update based on response from submitting the recovery flow
        setBanner(undefined)
        setLoading(true)

        newTeamInvitationClient()
            .acceptInvitation(searchParams.get('token') ?? '')
            .then((resp) => {
                setBanner(newSuccessBanner(`Successfully joined team!`))
                const url = new URL(`${window.location.protocol}//${SEARCH_DOMAIN}`)
                safelyNavigate(url, true)
            })
            .catch((err) => {
                // This means the current user not the user the invitation went to
                if (err.response?.data?.error) {
                    console.error('got error while accepting invitation', err.response.data)
                    setValid(false)
                    setBanner(newErrorBanner(err.response?.data?.error))
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Layout heading="Accept Invitation" banner={banner}>
            <SmallContainer>
                <Grid container spacing={3}>
                    {!invitationLoading && invitation !== null ? (
                        <>
                            <InvitationDisplay invitation={invitation} />
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    marginBottom: '10px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    id="accept-btn"
                                    disabled={invitationLoading || !isValid}
                                    onClick={handleAcceptInvite}
                                >
                                    Accept
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <></>
                    )}
                    {!invitationLoading && invitation === null ? <></> : <></>}
                    {invitationLoading ? (
                        <Grid
                            item
                            justifyContent="center"
                            display="flex"
                            style={{ marginTop: '40px', marginBottom: '20px' }}
                        >
                            <CircularProgress style={progressStyle} />
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>
            </SmallContainer>
        </Layout>
    )
}

export default AcceptInvitationGuard

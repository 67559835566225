import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AuthContext } from '../components/AuthProvider'
import { NotFoundSubheader } from '../components/Subheader'
import SiteMap from '../components/SiteMap'
import { guardSession, SessionCheck, SessionStateGuard } from '../helpers/sessionGuard'
import { checkVerificationFlow } from '../helpers/kratosFlowHandler'
import { useBannerUpdateFromLocation } from '../helpers/useBannerUpdate'
import { Layout, LargeContainer } from '../Layout'

const Error404: FC<{}> = (): ReactElement => {
    const { session } = useContext(AuthContext)
    const [sessionCheck, setSessionCheck] = useState<SessionCheck>(SessionCheck.Unverified)

    const [banner, setBanner] = useBannerUpdateFromLocation()
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        const flowId = searchParams.get('flow')
        if (flowId !== null) {
            // Sets the banner on a found verification flow with a message otherwise
            // removes the flow query param if the call has a `GONE` http status code
            checkVerificationFlow(flowId, setBanner, searchParams, setSearchParams)
        }
    }, [])

    useEffect(() => {
        guardSession(session, {
            active: SessionStateGuard.ActiveOnly,
            onMatch: () => setSessionCheck(SessionCheck.Valid),
            onNoMatch: () => {
                setSessionCheck(SessionCheck.Invalid)
            },
        })
    }, [session])

    const isAuthenticated = sessionCheck === SessionCheck.Valid

    return (
        <Layout heading="404 Page Not Found" subheader={NotFoundSubheader} banner={banner}>
            <LargeContainer>
                <SiteMap authenticated={isAuthenticated} />
            </LargeContainer>
        </Layout>
    )
}

export default Error404

import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Button, Grid, Typography } from '@mui/material'

import { AuthContext } from './AuthProvider'
import { BannerMessage, newErrorBanner, newSuccessBanner } from './BannerMessage'

import {
    newPersonalAccessTokensClient,
    PatDtoWritable,
    PersonalAccessToken,
    PersonalAccessTokenSecret,
} from '../services/personalAccessTokens'
import { initCreatePATForm } from '../formValidation/formToken'
import {
    TextFormField,
    onBlurUpdateForm,
    onInputUpdateForm,
    onOptionalInputUpdateForm,
} from '../helpers/handlers'
import CreateDialog from './dialog/TokenCreate'
import { LoadingButton } from '@mui/lab'

export enum TokenFormAction {
    CREATE = 'create',
    EDIT = 'edit',
}

interface TokenFormProps {
    formAction: TokenFormAction
    editRowData: PersonalAccessToken | undefined
    setBanner: React.Dispatch<React.SetStateAction<BannerMessage | undefined>>
    onBack: () => void
}

const TokenForm = (props: TokenFormProps): ReactElement => {
    // FIXME: We assume the session is safe here...
    const { session } = useContext(AuthContext)
    const [form, setForm] = useState(initCreatePATForm())
    const [createToken, setCreateToken] = useState<string | undefined>(undefined)
    const [submitLoading, setSubmitLoading] = useState<boolean>(false)

    useEffect(() => {
        if (props.editRowData) {
            setForm(
                initCreatePATForm({
                    name: props.editRowData.name,
                    description: props.editRowData.description,
                })
            )
        }
    }, [])

    const createTokenSubmit = () => {
        const validatedForm = form.validate()
        setForm(validatedForm)
        if (!validatedForm.isValid()) {
            return
        }

        const kratosId = session!.identity!.id
        const requestDto: PatDtoWritable = {
            name: validatedForm.values.name,
            description: validatedForm.values.description,
        }

        setSubmitLoading(true)
        newPersonalAccessTokensClient()
            .createToken(kratosId, requestDto)
            .then((pat: PersonalAccessTokenSecret) => {
                setSubmitLoading(false)
                setCreateToken(pat.token)
                props.setBanner(newSuccessBanner(`Successfully created token: ${pat.shortToken}`))
            })
            .catch((err) => {
                setSubmitLoading(false)
                props.setBanner(newErrorBanner('Error! Unable to create token'))
            })
    }

    const editTokenSubmit = () => {
        const validatedForm = form.validate()
        setForm(validatedForm)
        if (!validatedForm.isValid()) {
            return
        }

        const kratosId = session!.identity!.id
        const requestDto: PatDtoWritable = {
            name: validatedForm.values.name,
            description: validatedForm.values.description,
        }
        setSubmitLoading(true)
        newPersonalAccessTokensClient()
            .updateToken(kratosId, props.editRowData!.shortToken, requestDto)
            .then((pat: PersonalAccessToken) => {
                setSubmitLoading(false)
                props.onBack()
                props.setBanner(newSuccessBanner(`Successfully updated token: ${pat.shortToken}`))
            })
            .catch((err) => {
                setSubmitLoading(false)
                props.setBanner(
                    newErrorBanner(
                        `Error! Unable to update token: ${props.editRowData!.shortToken}`
                    )
                )
            })
    }

    let buttonText = ''
    let formText = ''
    let submitButtonAction = () => {}
    if (props.formAction === TokenFormAction.CREATE) {
        buttonText = 'Create New Token'
        formText = 'Add New Token'
        submitButtonAction = createTokenSubmit
    } else {
        buttonText = 'Update Token'
        formText = 'Edit Existing Token'
        submitButtonAction = editTokenSubmit
    }

    return (
        <Grid item xs={12} md={10} sx={{ margin: 'auto' }}>
            <CreateDialog
                open={createToken !== undefined}
                onClose={props.onBack}
                createdToken={createToken!}
            />
            <Grid container justifyContent="space-between">
                <Typography sx={{ mt: 2, mb: 1, fontWeight: 'bold' }} variant="h6" component="div">
                    Personal Access Tokens // {formText}
                </Typography>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <TextFormField
                        id="name"
                        label="Name"
                        value={form.values['name']}
                        message={form.messages['name']}
                        onBlur={onBlurUpdateForm('name', form, setForm)}
                        onInput={onInputUpdateForm('name', form, setForm)}
                        InputProps={{
                            autoFocus: true,
                            placeholder: 'MyProject Token',
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFormField
                        id="description"
                        label="Description"
                        value={form.values['description'] ?? ''}
                        message={form.messages['description']}
                        onBlur={onBlurUpdateForm('description', form, setForm)}
                        onInput={onOptionalInputUpdateForm('description', form, setForm)}
                        InputProps={{
                            placeholder: 'A token for MyProject',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: '20px', margin: 'auto' }}>
                    <Grid container justifyContent="space-between" sx={{ paddingTop: '20px' }}>
                        <Button id="back-btn" color="secondary" onClick={props.onBack}>
                            Back
                        </Button>
                        <LoadingButton
                            loading={submitLoading}
                            id="submit-token-btn"
                            onClick={submitButtonAction}
                        >
                            {buttonText}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TokenForm

import { z } from 'zod'
import { BaseValidator, Messages } from './baseValidator'
import { csrfTokenField, emailField } from './fields'

const VerifyFormSchema = z.object({
    email: emailField,
    csrfToken: csrfTokenField,
})

export type VerifyFormData = z.infer<typeof VerifyFormSchema>

class VerifyFormValidator extends BaseValidator<VerifyFormData> {
    schema(): any {
        return VerifyFormSchema
    }

    new(
        initial: VerifyFormData,
        messages: Messages<VerifyFormData> = {} as Messages<VerifyFormData>
    ): VerifyFormValidator {
        return new VerifyFormValidator(initial, messages)
    }
}

const defaultFormValues: VerifyFormData = {
    email: '',
    csrfToken: '',
}

export const initVerifyForm = (initValues: Partial<VerifyFormData> = {}) => {
    return new VerifyFormValidator({
        ...defaultFormValues,
        ...initValues,
    })
}

import { z } from 'zod'
import { BaseValidator, Messages } from './baseValidator'
import { captchaTokenField, csrfTokenField, emailField } from './fields'

const RecoverAccountFormSchema = z.object({
    email: emailField,
    csrfToken: csrfTokenField,
    captchaToken: captchaTokenField,
})

export type RecoverAccountFormData = z.infer<typeof RecoverAccountFormSchema>

class RecoverAccountFormValidator extends BaseValidator<RecoverAccountFormData> {
    schema(): any {
        return RecoverAccountFormSchema
    }

    new(
        initial: RecoverAccountFormData,
        messages: Messages<RecoverAccountFormData> = {} as Messages<RecoverAccountFormData>
    ): RecoverAccountFormValidator {
        return new RecoverAccountFormValidator(initial, messages)
    }
}

const defaultFormValues: RecoverAccountFormData = {
    email: '',
    csrfToken: '',
    captchaToken: '',
}

export const initRecoverAccountForm = (initValues: Partial<RecoverAccountFormData> = {}) => {
    return new RecoverAccountFormValidator({
        ...defaultFormValues,
        ...initValues,
    })
}

import React, { ReactElement, FC, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { CircularProgress, Grid } from '@mui/material'

import Layout, { LargeContainer } from '../../Layout'
import DeleteDialog from '../../components/dialog/TokenDelete'
import TokenForm, { TokenFormAction } from '../../components/TokenForm'
import { TokenList, TokenListHeader } from '../../components/list/TokenList'
import { AuthContext } from '../../components/AuthProvider'
import { newErrorBanner, newSuccessBanner } from '../../components/BannerMessage'
import { refreshSessionBanner } from '../../components/BannerMessageTypes'
import { guardSession, SessionCheck, SessionStateGuard } from '../../helpers/sessionGuard'
import { useBannerUpdate } from '../../helpers/useBannerUpdate'
import {
    newPersonalAccessTokensClient,
    PersonalAccessToken,
} from '../../services/personalAccessTokens'

const progressStyle = { width: '15%', height: '15%', display: 'flex', justifyContent: 'center' }

const PersonalAccessTokens: FC<any> = (): ReactElement => {
    const { accountsPersonalAccessTokens } = useFlags()
    const navigate = useNavigate()

    const { session, isPrivileged } = useContext(AuthContext)
    const [sessionCheck, setSessionCheck] = useState<SessionCheck>(SessionCheck.Unverified)
    const [banner, setBanner] = useBannerUpdate()

    const [rows, setRows] = useState<Array<PersonalAccessToken>>([])

    const [createToken, setCreateToken] = useState(false)
    const [editToken, setEditToken] = useState(false)
    const [deleteToken, setDeleteToken] = useState(false)
    const [listLoading, setListLoading] = useState<boolean>(false)
    const [selectedTokenId, setSelectedTokenId] = useState<string | undefined>(undefined)

    if (!accountsPersonalAccessTokens) {
        navigate('/login', {
            state: {
                banner: {
                    message: 'That page is currently under construction.',
                    type: 'success',
                },
            },
        })
    }

    useEffect(() => {
        guardSession(session, {
            active: SessionStateGuard.ActiveOnly,
            onMatch: () => setSessionCheck(SessionCheck.Valid),
            onNoMatch: () => {
                setSessionCheck(SessionCheck.Invalid)
            },
        })

        listSessionIdentityTokens()
    }, [session])

    useEffect(() => {
        if (sessionCheck === SessionCheck.Invalid) {
            navigate('/login', {
                state: {
                    banner: {
                        message: 'You must be logged in to manage user API Keys',
                        type: 'success',
                    },
                },
            })
        }
        if (sessionCheck !== SessionCheck.Valid) {
            return
        }
    }, [sessionCheck])

    const listSessionIdentityTokens = () => {
        if (session !== undefined && session !== null && session.identity !== undefined) {
            const kratosId = session.identity.id
            setListLoading(true)
            newPersonalAccessTokensClient()
                .listTokens(kratosId)
                .then((tokenList) => {
                    setRows(tokenList)
                    setListLoading(false)
                })
                .catch((err) => {
                    setBanner(newErrorBanner(`Error! Unable to list account tokens`))
                    setListLoading(false)
                })
        }
    }

    const verifyPrivilegedSession = () => {
        if (sessionCheck !== SessionCheck.Valid || !isPrivileged) {
            const urlParams = new URLSearchParams({
                refresh: 'true',
                return_to: window.location.toString(), // eslint-disable-line camelcase
                email: session?.identity?.traits.email,
            })
            navigate(`/login?${urlParams.toString()}`, {
                state: {
                    banner: refreshSessionBanner,
                    replace: true,
                },
            })
        }
    }

    const createNewKey = () => {
        verifyPrivilegedSession()
        setCreateToken(!createToken)
        if (banner !== undefined) {
            setBanner(undefined)
        }
    }

    const deleteExistingKey = (row: PersonalAccessToken) => {
        setDeleteToken(!deleteToken)
        if (banner !== undefined) {
            setBanner(undefined)
        }
        setSelectedTokenId(row.shortToken)
    }

    const editExistingKey = (row: PersonalAccessToken) => {
        verifyPrivilegedSession()
        setEditToken(!editToken)
        if (banner !== undefined) {
            setBanner(undefined)
        }
        setSelectedTokenId(row.shortToken)
    }

    const getSelectedToken = (shortToken: string | undefined): PersonalAccessToken | undefined =>
        shortToken !== undefined
            ? rows.find((t: PersonalAccessToken) => t.shortToken === shortToken)
            : undefined

    const removeRowCallback = (deleteId: string) => {
        const kratosId = session!.identity!.id
        newPersonalAccessTokensClient()
            .deleteToken(kratosId, deleteId)
            .then((resp) => {
                setBanner(newSuccessBanner(`Successfully deleted token: ${deleteId}`))
            })
            .catch((err) => {
                setBanner(newErrorBanner(`Error! Unable to delete token: ${deleteId}`))
            })

        setRows(rows.filter((row) => row.shortToken !== deleteId))
    }

    return (
        <Layout heading="Account Personal Access Tokens" banner={banner}>
            <LargeContainer>
                {createToken || editToken ? (
                    // Display the edit/create token view
                    <>
                        <TokenForm
                            formAction={createToken ? TokenFormAction.CREATE : TokenFormAction.EDIT}
                            editRowData={getSelectedToken(selectedTokenId)}
                            setBanner={setBanner}
                            onBack={() => {
                                setCreateToken(false)
                                setEditToken(false)
                                setSelectedTokenId(undefined)
                                listSessionIdentityTokens()
                            }}
                        />
                    </>
                ) : (
                    // Display the list of existing tokens
                    <>
                        <TokenListHeader onCreate={createNewKey} />
                        {listLoading ? (
                            <Grid
                                item
                                justifyContent="center"
                                display="flex"
                                style={{ marginTop: '40px', marginBottom: '20px' }}
                            >
                                <CircularProgress style={progressStyle} />
                            </Grid>
                        ) : (
                            <TokenList
                                pats={rows}
                                onEdit={editExistingKey}
                                onDelete={deleteExistingKey}
                            />
                        )}
                    </>
                )}
                <DeleteDialog
                    open={deleteToken}
                    onClose={() => {
                        setDeleteToken(false)
                        setSelectedTokenId(undefined)
                    }}
                    tokenDeleteRow={getSelectedToken(selectedTokenId)}
                    removeToken={removeRowCallback}
                />
            </LargeContainer>
        </Layout>
    )
}

export default PersonalAccessTokens

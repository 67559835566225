import React, { FC, KeyboardEvent, ReactElement } from 'react'
import { FormHelperText, StandardTextFieldProps, TextField } from '@mui/material'
import { FormLabel } from '../styled/styles'

import 'react-phone-number-input/style.css'
import PhoneInput, { Value as E164Number } from 'react-phone-number-input'
import { BaseValidator, KeysOfType } from '../formValidation/baseValidator'
import { MESSAGE_REQUIRED } from '../formValidation/constants'

export const handleEnterPress = (nextHandler: () => void) => (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
        nextHandler()
    }
}

export interface InputMessageProps {
    dataTestId: string
    message: string | undefined
}

export const InputMessage: FC<InputMessageProps> = ({
    dataTestId,
    message,
}: InputMessageProps): ReactElement => {
    if (message === undefined) {
        return <></>
    }

    return (
        <FormHelperText error={true} data-testid={dataTestId}>
            {message}
        </FormHelperText>
    )
}

export interface FormFieldProps extends StandardTextFieldProps {
    id: string
    label: string
    value: string
    message?: string
    required?: boolean
    requiredLabel?: boolean
}

export const TextFormField: FC<FormFieldProps> = (props: FormFieldProps): ReactElement => {
    const { id, label, value, message, required, requiredLabel, InputProps, ...textFieldProps } =
        props

    const requiredIndicator = requiredLabel ? <span title={MESSAGE_REQUIRED}>{' *'}</span> : <></>

    return (
        <div>
            <FormLabel htmlFor={id}>
                {label}
                {requiredIndicator}
            </FormLabel>
            <TextField
                id={id}
                value={value}
                margin="normal"
                variant="standard"
                color="primary"
                required={required === true}
                InputProps={
                    {
                        ['data-testid']: id,
                        disableUnderline: true,
                        style: {
                            fontFamily: `InterBold, serif`,
                        },
                        ...(InputProps || {}),
                    } as StandardTextFieldProps['InputProps']
                }
                {...textFieldProps}
            />
            <InputMessage dataTestId={`${id}-message`} message={message} />
        </div>
    )
}

export interface PhoneFieldProps {
    id: string
    label: string
    value: string | null
    message?: string
    onBlur?: () => void
    onChange: (value?: E164Number | undefined) => void
}

export const PhoneFormField: FC<PhoneFieldProps> = (props: PhoneFieldProps): ReactElement => {
    const { id, label, value, message, onBlur, onChange } = props
    return (
        <div>
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <PhoneInput
                id={id}
                data-testid={id}
                defaultCountry="US"
                style={{
                    padding: '12px 20px',
                    margin: '5px 0 8px 0',
                    background: '#f6f7f9',
                    borderRadius: '4px',
                }}
                placeholder="(201) 555-0123"
                value={value || ''}
                onBlur={onBlur}
                onChange={onChange}
            />
            <InputMessage dataTestId={`${id}-message`} message={message} />
        </div>
    )
}

export const onBlurUpdateForm = <T, K extends Exclude<keyof T, symbol | number>>(
    key: K,
    form: BaseValidator<T>,
    setForm: React.Dispatch<React.SetStateAction<BaseValidator<T>>>
) => {
    return () => {
        // Only validate on blur if there's a value in the field
        //
        // In the context of the app, `key` is never a user provided value, and is hardcoded in the props
        // of components that use this handler. Because of that, it's safe to ignore semgrep security
        // warnings about code injection
        // nosemgrep: gitlab.eslint.detect-object-injection
        if (form.values[key]) {
            setForm(form.validateField(key))
        }
    }
}

export function onInputUpdateForm<T, F extends KeysOfType<T, V>, V extends string & T[F]>(
    field: F,
    form: BaseValidator<T>,
    setForm: React.Dispatch<React.SetStateAction<BaseValidator<T>>>
): any {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm(form.set(field, event.target.value as V, false))
    }
}

export function onOptionalInputUpdateForm<
    T,
    F extends KeysOfType<T, V>,
    V extends (string | null) & T[F]
>(
    field: F,
    form: BaseValidator<T>,
    setForm: React.Dispatch<React.SetStateAction<BaseValidator<T>>>
): any {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value ? event.target.value : null
        setForm(form.set(field, value as V, false))
    }
}

export function onPhoneUpdateForm<T, F extends KeysOfType<T, V>, V extends (string | null) & T[F]>(
    field: F,
    form: BaseValidator<T>,
    setForm: React.Dispatch<React.SetStateAction<BaseValidator<T>>>
): any {
    return (value?: E164Number | undefined) => {
        const phoneValue = value === undefined || value === '' ? null : (value as string)
        const forceValidate = phoneValue === null
        setForm(form.set(field, phoneValue as V, forceValidate))
    }
}

export function onCheckboxUpdateForm<T, F extends KeysOfType<T, V>, V extends string & T[F]>(
    field: F,
    form: BaseValidator<T>,
    setForm: React.Dispatch<React.SetStateAction<BaseValidator<T>>>
): any {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm(form.set(field, event.target.checked.toString() as V))
    }
}

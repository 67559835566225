import { BASE_DOMAIN, RETURN_TO_URLS_REGEXES } from '../config'
import { urlIsSafe } from './safelyNavigate'

const RETURN_TO_URL_REGEXES = RETURN_TO_URLS_REGEXES?.split(',')

function parseURL(url: string): URL | null {
    try {
        return new URL(url)
    } catch (err) {
        console.warn(`got error while parsing return_to parameter: ${err}`)
        return null
    }
}

export default function getReturnTo(
    params: URLSearchParams,
    defaultReturnTo: URL | null = null
): URL | null {
    // If not return_to param was provided, just go to the search app
    const returnToParam = params.get('return_to')
    if (!returnToParam) {
        return defaultReturnTo
    }

    // Parse the return_to address, returning the default if parsing fails
    const parsedUrl = parseURL(returnToParam)
    if (parsedUrl === null) {
        return defaultReturnTo
    }

    // Return the default if the url protocol is not safe (http/https)
    if (!urlIsSafe(parsedUrl)) {
        return defaultReturnTo
    }

    // Return the default if the url hostname does not match the censys domain
    if (!parsedUrl.host.endsWith(BASE_DOMAIN)) {
        return defaultReturnTo
    }

    return parsedUrl
}

export function parseAndValidateReturnTo(returnTo: string | null): URL | null {
    if (returnTo === null) {
        return null
    }

    const returnUrl = parseURL(returnTo)
    if (returnUrl === null) {
        return null
    }

    if (!isValidRedirect(returnUrl.toString())) {
        console.warn(`invalid return_to URL: ${returnTo}`)
        return null
    }

    return returnUrl
}

export function isValidRedirect(url: string): boolean {
    if (RETURN_TO_URL_REGEXES === undefined) {
        console.warn('No allowed return_to URLs are defined')
        return false
    }
    for (const allowedURLRegex of RETURN_TO_URL_REGEXES) {
        // URL decode the strings to avoid issues with URL encoding
        const decodedURL = decodeURIComponent(url)

        // Check if the URL matches the allowed URL regex
        const regex = new RegExp(allowedURLRegex)
        if (regex.test(decodedURL)) {
            return true
        }
    }
    return false
}

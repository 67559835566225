import React, { ReactElement, FC } from 'react'
import { Stack, Divider } from '@mui/material'
import styled from 'styled-components'
import { ThemeLink } from '../styled/styles'

const MBParagraph = styled.p`
    margin-bottom: 0.5em;
`

const footerStyle = {
    color: '#70777f',
    fontSize: '12px',
    marginBottom: '0.5em',
    margin: 'auto',
    fontFamily: `'InterBold', serif`,
}

export const Footer: FC = (): ReactElement => {
    return (
        <div style={{ ...footerStyle, paddingBottom: '60px' }}>
            <Stack component="footer" alignItems="center">
                <MBParagraph>© 2022 Censys</MBParagraph>
                <div>
                    Need Help?{' '}
                    <ThemeLink href="https://support.censys.io/" target="_blank" rel="noopener">
                        Help Center
                    </ThemeLink>{' '}
                    or <ThemeLink href="mailto:support@censys.io">support@censys.io</ThemeLink>
                </div>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                    textAlign="center"
                    marginTop={'5px'}
                >
                    <ThemeLink href="http://search.censys.io/search/language">
                        Search Documentation
                    </ThemeLink>
                    <ThemeLink href="http://search.censys.io/api">API Documentation</ThemeLink>
                    <ThemeLink href="https://support.censys.io/hc/en-us/articles/360038761891-Research-Access-to-Censys-Data">
                        Research Access
                    </ThemeLink>
                </Stack>
            </Stack>
        </div>
    )
}

export default Footer

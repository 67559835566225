import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { stateFromUnknown, bannerStateValidator } from './pageState'
import { BannerMessage } from '../components/BannerMessage'

export const useBannerUpdate = (): [
    BannerMessage | undefined,
    React.Dispatch<React.SetStateAction<BannerMessage | undefined>>
] => {
    const [banner, setBanner] = useState<BannerMessage | undefined>(undefined)

    useEffect(() => {
        // On the clearing of a banner we do not scroll to the top of the page and instead
        // just keep the user where they currently are.
        if (banner === undefined) {
            return
        }
        // On any updates to banner state we will scroll to the top of the page to clearly
        // present the message to the user.
        window.scrollTo(0, 0)
    }, [banner])

    return [banner, setBanner]
}

export const useBannerUpdateFromLocation = (): [
    BannerMessage | undefined,
    React.Dispatch<React.SetStateAction<BannerMessage | undefined>>
] => {
    const location = useLocation()
    const [banner, setBanner] = useBannerUpdate()

    useEffect(() => {
        // If we are redirected from another page on the app then check the router location
        // state and see if there is a message that should be presented on the banner.
        const state = stateFromUnknown(location.state, bannerStateValidator)
        if (state?.banner) {
            setBanner(state.banner)
        }
    }, [])

    return [banner, setBanner]
}

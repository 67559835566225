import { SEARCH_DOMAIN } from '../config'

export const defaultReturnTo = new URL(`${window.location.protocol}//${SEARCH_DOMAIN}`)

function navigate(url: URL, replace: boolean) {
    if (replace) {
        window.location.replace(url.toString())
    } else {
        window.location.href = url.toString()
    }
}

export function urlIsSafe(url: URL): boolean {
    return ['http:', 'https:'].includes(url.protocol)
}

export default function safelyNavigate(url: URL, replace: boolean = false) {
    if (urlIsSafe(url)) {
        console.log('navigating away to: ' + url)
        navigate(url, replace)
    } else {
        // Malicious behavior, just return them to the default route
        console.log('navigating away todefault: ' + defaultReturnTo)
        navigate(defaultReturnTo, replace)
    }
}

import { z } from 'zod'
import { MESSAGE_MUST_MATCH } from './constants'
import { BaseValidator, Messages } from './baseValidator'
import { csrfTokenField, setPasswordField } from './fields'

const ChangePasswordFormSchema = z
    .object({
        password: setPasswordField,
        confirmPassword: setPasswordField,
        csrfToken: csrfTokenField,
    })
    .refine((val) => val.password === val.confirmPassword, {
        path: ['confirmPassword'],
        message: MESSAGE_MUST_MATCH,
    })

export type ChangePasswordFormData = z.infer<typeof ChangePasswordFormSchema>

class ChangePasswordFormValidator extends BaseValidator<ChangePasswordFormData> {
    schema(): any {
        return ChangePasswordFormSchema
    }

    new(
        initial: ChangePasswordFormData,
        messages: Messages<ChangePasswordFormData> = {} as Messages<ChangePasswordFormData>
    ): ChangePasswordFormValidator {
        return new ChangePasswordFormValidator(initial, messages)
    }
}

const defaultFormValues: ChangePasswordFormData = {
    password: '',
    confirmPassword: '',
    csrfToken: '',
}

export const initChangePasswordForm = (initValues: Partial<ChangePasswordFormData> = {}) => {
    return new ChangePasswordFormValidator({
        ...defaultFormValues,
        ...initValues,
    })
}

import axiosFactory, { AxiosInstance, AxiosResponse } from 'axios'

const ACCOUNTS_DOMAIN = window.location.hostname

const INVITATIONS_API_ENDPOINT = '/my-account/invitation'

const MS_PER_HOUR = 3_600_000

export const DEFAULT_ZERO_TIME = '0001-01-01T00:00:00Z'

const translateZeroTime = (timestamp: string): string | null => {
    return timestamp === DEFAULT_ZERO_TIME ? null : timestamp
}

export interface InvitationDto {
    relation: string
    team: string
    email: string
    create_time: string
    expiration_time: string
}

export class TeamInvitation {
    relation: string
    team: string
    email: string
    createTime: Date
    expirationTime: Date

    constructor(
        relation: string,
        team: string,
        email: string,
        createTime: string,
        expirationTime: string
    ) {
        this.relation = relation
        this.team = team
        this.email = email
        this.createTime = new Date(createTime)
        this.expirationTime = new Date(expirationTime)
    }

    static fromDto(dto: InvitationDto): TeamInvitation {
        return new TeamInvitation(
            dto.relation,
            dto.team,
            dto.email,
            dto.create_time,
            dto.expiration_time
        )
    }

    public hoursRemaining(): number {
        const remainingMs = this.expirationTime.getTime() - new Date().getTime()
        if (remainingMs <= 0) {
            return 0
        }
        return Math.floor(remainingMs / MS_PER_HOUR)
    }
}

class TeamInvitationsApiImpl {
    host: string
    axios: AxiosInstance

    constructor(axios: AxiosInstance) {
        this.host = ACCOUNTS_DOMAIN
        this.axios = axios
    }

    buildTokensUrl = (token: string | null): URL => {
        const url = new URL(INVITATIONS_API_ENDPOINT, `${window.location.protocol}//${this.host}`)
        if (token !== null) {
            url.searchParams.append('token', token)
        }
        return url
    }

    getInvitation = async (token: string): Promise<TeamInvitation> => {
        const intitationUrl = this.buildTokensUrl(token)
        return this.axios
            .get(intitationUrl.toString())
            .then((response: AxiosResponse<InvitationDto>) => {
                return TeamInvitation.fromDto(response.data)
            })
    }

    acceptInvitation = (token: string) => {
        const intitationUrl = this.buildTokensUrl(null)
        return this.axios.post(intitationUrl.toString(), { token: token })
    }
}

export const newTeamInvitationClient = () => {
    const axios = axiosFactory.create()
    axios.defaults.withCredentials = true
    return new TeamInvitationsApiImpl(axios)
}

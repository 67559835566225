import React, { ReactElement, FC, useContext, useEffect, useState } from 'react'
import { Button, Grid } from '@mui/material'
import { AuthContext } from '../../components/AuthProvider'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    FrontendApiUpdateSettingsFlowRequest,
    SettingsFlow,
    UiText,
    UpdateSettingsFlowWithPasswordMethod,
} from '@ory/client'
import Layout, { SmallContainer } from '../../Layout'
import {
    handleEnterPress,
    TextFormField,
    onBlurUpdateForm,
    onInputUpdateForm,
} from '../../helpers/handlers'
import { newKratosSdk } from '../../services/kratos'
import { csrfFromUiContainer, parseUIErrors } from '../../helpers/kratos'
import { AxiosError } from 'axios'
import { guardSession, SessionCheck, SessionStateGuard } from '../../helpers/sessionGuard'
import PasswordShowAdornment from '../../components/PasswordShowAdornment'
import { useBannerUpdate } from '../../helpers/useBannerUpdate'
import { newErrorBanner } from '../../components/BannerMessage'
import { initChangePasswordForm } from '../../formValidation/formChangePassword'
import {
    formInitializationBanner,
    passwordChangedBanner,
    passwordChangedRedirectBanner,
    refreshSessionBanner,
} from '../../components/BannerMessageTypes'
import safelyNavigate from '../../helpers/safelyNavigate'
import getReturnTo from '../../helpers/getReturnTo'

const ChangePassword: FC<any> = (): ReactElement => {
    const navigate = useNavigate()

    const { session, isPrivileged } = useContext(AuthContext)
    const [sessionCheck, setSessionCheck] = useState<SessionCheck>(SessionCheck.Unverified)
    const [searchParams] = useSearchParams()
    const [banner, setBanner] = useBannerUpdate()
    const [flow, setFlow] = useState<SettingsFlow>()
    const [form, setForm] = useState(initChangePasswordForm())

    const [showConfirm, setShowConfirm] = useState<boolean>(false)
    const [showPassword, setShowPassword] = useState<boolean>(false)

    useEffect(() => {
        guardSession(session, {
            active: SessionStateGuard.ActiveOnly,
            onMatch: () => setSessionCheck(SessionCheck.Valid),
            onNoMatch: () => {
                setSessionCheck(SessionCheck.Invalid)
            },
        })
    }, [session])

    useEffect(() => {
        console.debug('[useEffect(sessionCheck)] sessionCheck changed...', sessionCheck)
        if (sessionCheck === SessionCheck.Invalid) {
            navigate('/login')
        }
        if (sessionCheck !== SessionCheck.Valid) {
            return
        }

        // Check if the users session is within the privileged session duration
        // Session ought to be present by this point (as indicated by the SessionCheck state)
        // TODO: Register a timeout to trigger if the session becomes not privileged while
        // the user is looking at this page. That timeout should then do the same logic as
        // in the following condition block
        if (!isPrivileged) {
            console.debug(
                'Privileged session is required, but session is too old. Redirecting to login for session refresh...'
            )
            // The users session is not privileged, so we need to redirect them to login to
            // refresh the session before they can update their password.
            // This blindly assumes the user is of schema user_v1
            const urlParams = new URLSearchParams({
                refresh: 'true',
                return_to: window.location.toString(), // eslint-disable-line camelcase
                email: session?.identity?.traits.email,
            })
            navigate(`/login?${urlParams.toString()}`, {
                state: {
                    banner: refreshSessionBanner,
                    replace: true,
                },
            })
        }

        // Initialize the settings flow
        newKratosSdk()
            .createBrowserSettingsFlow()
            .then(({ data }) => {
                setFlow(data)
                setForm(form.set('csrfToken', csrfFromUiContainer(data.ui)))
            })
            .catch((err: AxiosError) => {
                switch (err.response?.status) {
                    case 400:
                        console.error('Error when initializing self service recovery flow...')
                }
                console.error(err)
            })
    }, [sessionCheck])

    const handleFormSubmit = () => {
        const validatedForm = form.validate()
        setForm(validatedForm)

        if (!validatedForm.isValid()) {
            // If the form isn't valid, just return. We don't need to display
            // a banner or anything here since the `form.validate()` call above
            // will have updated state, thereby causing error messages to be rendered
            // for any invalid input fields
            return
        }

        // Clear banner and have it update based on response from submitting the recovery flow
        setBanner(undefined)

        const settingsBody: {
            method: 'password'
        } & UpdateSettingsFlowWithPasswordMethod = {
            method: 'password',
            csrf_token: validatedForm.values.csrfToken, // eslint-disable-line camelcase
            password: validatedForm.values.password,
        }

        if (!flow?.id) {
            // somehow we've gotten a flow with no ID. log an error and return.
            console.error('Could not submit update request due to missing flow id.')
            setBanner(formInitializationBanner)
            return
        }

        submitKratosFlow(flow.id, settingsBody)
            .then((data) => {
                if (data.ui.messages !== undefined && data.ui.messages.length > 0) {
                    // Password successfully changed, now redirect to login page or the return_to value
                    const returnTo = getReturnTo(searchParams)
                    if (returnTo) {
                        // There was a return to value, so we need to navigate the user back to that
                        setBanner(passwordChangedRedirectBanner)
                        setTimeout(() => safelyNavigate(returnTo), 5000)
                    } else {
                        // There was no return to value, so we just return the user to the login page
                        navigate('/login', {
                            state: {
                                banner: passwordChangedBanner,
                            },
                        })
                    }
                    return
                }
            })
            .catch((err) => {
                console.error('Error handling form submission', err)
            })
    }

    const submitKratosFlow = (
        flowId: string,
        settingsBody: {
            method: 'password'
        } & UpdateSettingsFlowWithPasswordMethod
    ): Promise<SettingsFlow> =>
        new Promise((resolve, reject) => {
            const req: FrontendApiUpdateSettingsFlowRequest = {
                flow: flowId,
                updateSettingsFlowBody: settingsBody,
            }

            newKratosSdk()
                .updateSettingsFlow(req)
                .then(({ data }) => {
                    setFlow(data)
                    setForm(form.set('csrfToken', csrfFromUiContainer(data.ui)))
                    resolve(data)
                })
                .catch((err) => {
                    if (err.response?.status === 410) {
                        if (err.response?.data.error.id === 'self_service_flow_expired') {
                            const newFlowId = err.response.data.use_flow_id
                            submitKratosFlow(newFlowId, settingsBody).then(resolve).catch(reject)
                        } else {
                            console.error(
                                'Got unexpected error response from kratos status 410',
                                err
                            )
                        }
                        return
                    } else {
                        parseUIErrors(err.response?.data).forEach((message: UiText) => {
                            setBanner(newErrorBanner(message.text))
                        })
                    }
                })
        })

    return (
        <Layout heading="Change Password" banner={banner}>
            <SmallContainer>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextFormField
                            id="password"
                            label="Password"
                            value={form.values.password}
                            message={form.messages.password}
                            required={true}
                            autoFocus={true}
                            onBlur={onBlurUpdateForm('password', form, setForm)}
                            onInput={onInputUpdateForm('password', form, setForm)}
                            onKeyDown={handleEnterPress(handleFormSubmit)}
                            InputProps={{
                                type: showPassword ? 'text' : 'password',
                                placeholder: 'Enter Your Password',
                                endAdornment: <PasswordShowAdornment callback={setShowPassword} />,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFormField
                            id="confirmPassword"
                            label="Confirm Password"
                            value={form.values.confirmPassword}
                            message={form.messages.confirmPassword}
                            required={true}
                            onBlur={onBlurUpdateForm('confirmPassword', form, setForm)}
                            onInput={onInputUpdateForm('confirmPassword', form, setForm)}
                            onKeyDown={handleEnterPress(handleFormSubmit)}
                            InputProps={{
                                type: showConfirm ? 'text' : 'password',
                                placeholder: 'Re-Type Your Password',
                                endAdornment: <PasswordShowAdornment callback={setShowConfirm} />,
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            marginBottom: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Button id="reset-btn" onClick={handleFormSubmit}>
                            Change Password
                        </Button>
                    </Grid>
                </Grid>
            </SmallContainer>
        </Layout>
    )
}

export default ChangePassword

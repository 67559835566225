import React, { ReactElement, useState, useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { newKratosSdk } from '../services/kratos'
import { AxiosError, AxiosResponse } from 'axios'
import { Stack } from '@mui/material'
import { FrontendApiCreateBrowserLoginFlowRequest, LoginFlow } from '@ory/client'
import Layout, { SmallContainer } from '../Layout'
import LoadingBackdrop from '../components/LoadingBackdrop'
import LoginForm from '../components/LoginForm'
import ProductSelector from '../components/ProductSelector'
import { AuthContext } from '../components/AuthProvider'
import { newErrorBanner } from '../components/BannerMessage'
import { LoginSubheader } from '../components/Subheader'
import { getLoginFlow, checkVerificationFlow } from '../helpers/kratosFlowHandler'
import { SessionStateGuard, guardSession, SessionCheck } from '../helpers/sessionGuard'
import { useBannerUpdateFromLocation } from '../helpers/useBannerUpdate'
import { navigateWithAsmCookie, redirectToWithAsmCookie } from '../services/search'
import CharcoalParagraph from '../styled/Paragraph'
import { ThemeLink } from '../styled/styles'
import { existingSessionBanner } from '../components/BannerMessageTypes'
import { SEARCH_DOMAIN } from '../config'
import { parseAndValidateReturnTo } from '../helpers/getReturnTo'

const Login = (): ReactElement => {
    const { getSession, session } = useContext(AuthContext)
    const [sessionCheck, setSessionCheck] = useState<SessionCheck>(SessionCheck.Unverified)
    const [searchParams, setSearchParams] = useSearchParams()

    // Configures the login form state
    const [flow, setFlow] = useState<LoginFlow | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [banner, setBanner] = useBannerUpdateFromLocation()

    // This is the URL to redirect the user to after login. This is either set
    // by the return_to query param or by the user selecting a product.
    // The default value of undefined means the query parameter has not been
    // processed yet. The null value means there is no valid redirect URL from
    // the query param and the user must select a product to proceed.
    //
    // This ultimately will not actually cause any redirect to take place but is
    // instead used as a way to progress the login form. The data that actually
    // causes the redirect will be the value in the query params
    const [redirectUrl, setRedirectUrl] = useState<URL | null | undefined>(undefined)

    const [registrationReturnTo] = useState<string | null>(() => {
        const item = window.localStorage.getItem('registration_return_to')
        if (item !== null) {
            return JSON.parse(item)
        }
        return item
    })

    // Begins the login flow with Kratos
    // This is required to generate the CSRF cookie required by the login flow.
    const initializeFlow = () => {
        const flowRequest: FrontendApiCreateBrowserLoginFlowRequest = {
            refresh: searchParams.get('refresh') === 'true',
            aal: 'aal1',
            // This is only used for the OIDC login flow. The password flow
            // will complete by returning a session and the frontend handles
            // the redirect. The OIDC flow results in a navigation away from
            // the frontend, so we need to provide a return_to param to
            // redirect the user back to the search app's account redirect
            // endpoint after login to set the JWTs for ASM. The account
            // redirect endpoint will then return the user to the original
            // return_to param.
            returnTo: redirectToWithAsmCookie(searchParams.get('return_to')).toString() || '/',
        }
        newKratosSdk()
            .createBrowserLoginFlow(flowRequest, {
                // Include UTM params if they exist...
                params: searchParams,
            })
            .then((response: AxiosResponse<LoginFlow>) => {
                const { data: flow } = response
                setFlow(flow)
            })
            // TODO - All kratos call should have retry mechanism to improve this
            .catch((err: AxiosError<any, any>) => {
                if (err.response?.status === 400) {
                    const body = err.response.data
                    if (body?.error?.id === 'session_already_available') {
                        // In this case, we're already logged in, but lost the session state
                        // in localStorage, so we sync state and then wait for the user to
                        // select a product
                        getSession()
                        return
                    }
                }
                if (err.response?.data?.error) {
                    // log the error message but just display the status message since this will
                    // typically be an internal server error
                    console.error('got error while initializing login flow?', err.response.data)
                    setBanner(newErrorBanner(err.response.data.error.status))
                } else {
                    setBanner(newErrorBanner(err.response?.data?.message))
                }
                setIsLoading(false)
            })
    }

    const navigateAway = () => {
        const returnTo = searchParams.get('return_to')
        navigateWithAsmCookie(
            // FIXME: This is a little hacky but honestly trying to rework how
            //	this `return_to` logic works is more work than I wanna take on.
            //
            // Anyways it is duplicated in that I send the `searchParams` down
            // to this function but I just invoke the `utm` related helper
            // in the navigate function to simplify the time when we can just
            // send the `searchParams` instead of passing the `return_to`
            // argument independently
            registrationReturnTo || returnTo || '',
            searchParams
        )
        localStorage.removeItem('registration_return_to')
    }

    const handleOnSuccess = () => {
        setIsLoading(true)
        navigateAway()
    }

    const handleOnBack = () => {
        setRedirectUrl(null)
        setFlow(undefined)
        setBanner(undefined)
    }

    useEffect(() => {
        // NOTE: Although these methods have a way of setting search params
        //	they **should not** remove them in that if a UTM param was present
        //	it should continue to stay present whether a `login` or `verification`
        //	flow is initialized here

        // Validate the return_to value before setting it as the redirect URL
        setRedirectUrl(parseAndValidateReturnTo(searchParams.get('return_to')))
        const flowId = searchParams.get('flow')
        const noOrgUI = searchParams.get('no_org_ui')
        if (flowId !== null && noOrgUI === 'true') {
            // Sets the banner on a found login flow with a message
            getLoginFlow(flowId, setBanner, searchParams, setSearchParams, setRedirectUrl, setFlow)
        } else if (flowId !== null) {
            // Sets the banner on a found verification flow with a message otherwise
            // removes the flow query param if the call has a `GONE` http status code
            checkVerificationFlow(flowId, setBanner, searchParams, setSearchParams)
        }
    }, [])

    useEffect(
        () =>
            guardSession(session, {
                active: SessionStateGuard.ActiveOnly,
                methods: ['password', 'link_recovery', 'oidc'],
                onMatch: () => {
                    if (searchParams.get('refresh')) {
                        // If the refresh query param was provided, the user is trying to
                        // refresh their session, so we should let them authenticate even
                        // though they already have a password session
                        setSessionCheck(SessionCheck.Valid)
                    } else {
                        // We have a valid session and don't need to login again, so we set the
                        // session check to indicate that once the product changes we can
                        // redirect the user to the correct product via the search accounts
                        // product redirect endpoint
                        setSessionCheck(SessionCheck.Invalid)
                    }
                },
                onNoMatch: () => {
                    // We do not have a valid session, and should continue to login
                    setSessionCheck(SessionCheck.Valid)
                },
            }),
        [session]
    )

    // When the component is mounted and whenever an error occurs
    // generate a new login flow to be used on the next form submit
    useEffect(() => {
        // We must have an inactive session before initializing the login flow
        if (sessionCheck !== SessionCheck.Valid) {
            console.debug('[initializeFlow guard] short circuit: sessionCheck is not ready')
            return
        }

        // We must have chosen a product before initializing the login flow
        if (!redirectUrl) {
            console.debug('[initializeFlow guard] short circuit: redirect URL is not set')
            return
        }

        if (flow === undefined) {
            console.debug('[initializeFlow guard] initializing login flow')
            initializeFlow()
        }

        return () => {
            setFlow(undefined)
        }
    }, [sessionCheck, redirectUrl])

    useEffect(() => {
        // This effect navigates the user away from the login page if they're sufficiently logged in, and if a product is selected

        // If we have a redirect URL and a valid session then we do not need to present
        // the login form and can instead forward them to the search product
        // Short circuit this function if:
        //   - No redirect URL is set
        //   - The SessionCheck is Valid (meaning they _need_ to authenticate)
        //   - The SessionCheck is Unverified (meaning we don't know the session state yet)
        if (redirectUrl === null || sessionCheck !== SessionCheck.Invalid) {
            return
        }

        setIsLoading(true)
        setBanner(existingSessionBanner)
        handleOnSuccess()
    }, [redirectUrl, sessionCheck])

    // - Do not show the product card if the redirectUrl is undefined. An undefined value means
    //   we have not finished setting the initial state from the browser URL
    // - Show the product card if the login flow is undefined. We should not switch displays
    //   to the login card until the login flow is ready
    // - There is a very brief moment when a product has been selected
    //   (redirectUrl !== undefined && redirectUrl !== null) but the login flow has not been initialized
    //   (flow === undefined), during which we continue to display the product selection card to
    //   prevent the UI from flickering
    const productCard =
        redirectUrl !== undefined && flow === undefined ? (
            <ProductSelector setRedirectUrl={setRedirectUrl} />
        ) : (
            <></>
        )

    const loginCard =
        redirectUrl && flow !== undefined ? (
            <LoginForm
                flow={flow}
                setBanner={setBanner}
                onBack={handleOnBack}
                onSuccess={handleOnSuccess}
            />
        ) : (
            <></>
        )

    const isSessionAvailable = session !== undefined && session !== null ? true : false
    const forgotEmailLink = `${window.location.protocol}//${SEARCH_DOMAIN}/recover-email`
    return (
        <Layout
            heading="Log In"
            subheader={<LoginSubheader sessionAvailable={isSessionAvailable} />}
            banner={banner}
        >
            <SmallContainer>
                <LoadingBackdrop data-testid="login-loading-spinner" loading={isLoading} />
                {productCard}
                {loginCard}
            </SmallContainer>
            <Stack display="flex" alignItems="center" fontFamily="InterBold">
                <CharcoalParagraph>
                    Forgot your email?&nbsp;
                    <ThemeLink href={forgotEmailLink}>Get a reminder</ThemeLink>
                </CharcoalParagraph>
            </Stack>
        </Layout>
    )
}

export default Login

import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Grid } from '@mui/material'
import {
    FrontendApiUpdateRecoveryFlowRequest,
    RecoveryFlow,
    UpdateRecoveryFlowWithCodeMethodMethodEnum,
    UpdateRecoveryFlowWithLinkMethod,
} from '@ory/client'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { AuthContext } from '../components/AuthProvider'
import Layout, { SmallContainer } from '../Layout'
import { newKratosSdk } from '../services/kratos'
import { parseUINodeErrors, FlowExpirationError } from '../helpers/kratos'
import { checkRecoveryFlow } from '../helpers/kratosFlowHandler'
import CaptchaInput, { onCaptchaUpdateForm } from '../components/Captcha'
import {
    handleEnterPress,
    TextFormField,
    onBlurUpdateForm,
    onInputUpdateForm,
} from '../helpers/handlers'
import { csrfFromUiContainer } from '../helpers/kratos'
import { RecoverAccountSubheader } from '../components/Subheader'
import { guardSession, SessionCheck, SessionStateGuard } from '../helpers/sessionGuard'
import { useBannerUpdateFromLocation } from '../helpers/useBannerUpdate'
import { initRecoverAccountForm } from '../formValidation/formRecoverAccount'
import { newErrorBanner, newSuccessBanner } from '../components/BannerMessage'
import { recoveryFlowExpiredBanner } from '../components/BannerMessageTypes'

const RecoverAccount = (): ReactElement => {
    const { session } = useContext(AuthContext)
    const [sessionCheck, setSessionCheck] = useState<SessionCheck>(SessionCheck.Unverified)
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    const [banner, setBanner] = useBannerUpdateFromLocation()
    const [flow, setFlow] = useState<RecoveryFlow>()
    const [form, setForm] = useState(
        initRecoverAccountForm({
            email: searchParams.get('email') ?? '',
        })
    )

    useEffect(() => {
        const flowId = searchParams.get('flow')
        if (flowId !== null) {
            // Sets the banner on a found recovery flow with a message otherwise
            // removes the flow query param if the call has a `GONE` http status code
            checkRecoveryFlow(flowId, setBanner, searchParams, setSearchParams)
        }
    }, [])

    useEffect(
        () =>
            guardSession(session, {
                active: SessionStateGuard.ActiveOnly,
                onMatch: () => {
                    setSessionCheck(SessionCheck.Invalid)
                    // If the user is already logged in with a password, they don't need to fill out
                    // the form on this page. We can redirect them right to the password reset
                    // settings page.
                    navigate('/user/settings/change-password')
                },
                onNoMatch: () => {
                    // The user is not logged in, so they need to complete the form on this
                    // page to get a temporary session to reset their password.
                    setSessionCheck(SessionCheck.Valid)
                },
            }),
        [session]
    )

    useEffect(() => {
        console.debug('[useEffect(sessionCheck)] sessionCheck changed...', sessionCheck)
        if (sessionCheck !== SessionCheck.Valid) {
            return
        }

        console.debug('Initializing recovery flow')
        newKratosSdk()
            .createBrowserRecoveryFlow()
            .then(({ data }) => {
                setFlow(data)
                setForm(form.set('csrfToken', csrfFromUiContainer(data.ui)))
            })
            .catch((err: AxiosError) => {
                switch (err.response?.status) {
                    case 400:
                        console.error('Error when initializing self service recovery flow...')
                }
                console.error(err)
            })
    }, [sessionCheck])

    const handleSubmitRecovery = () => {
        console.debug('Submitting Recovery Flow Request')

        const validatedForm = form.validate()
        setForm(validatedForm)

        if (!validatedForm.isValid()) {
            // If the form isn't valid, just return. We don't need to display
            // a banner or anything here since the `form.validate()` call above
            // will have updated state, thereby causing error messages to be rendered
            // for any invalid input fields
            return
        }

        // Clear banner and have it update based on response from submitting the
        // recovery flow
        setBanner(undefined)

        const recoveryRequest: FrontendApiUpdateRecoveryFlowRequest = {
            flow: String(flow?.id),
            updateRecoveryFlowBody: {
                // eslint-disable-next-line camelcase
                csrf_token: validatedForm.values.csrfToken,
                email: validatedForm.values.email,
                method: UpdateRecoveryFlowWithCodeMethodMethodEnum.Link,
            },
        }

        const captchaResponse = validatedForm.values.captchaToken
        const options: AxiosRequestConfig = {
            headers: {
                'X-Captcha-Token': captchaResponse,
            },
        }

        // On submission, add the flow ID to the URL but do not navigate. This prevents the user losing
        // their data when they reload the page.
        //
        // Notice:
        // From here on we're not using validatedForm here because the form state may
        // have changed since we called form.validate(), and we don't want to override
        // any of those changes by setting the csrf on the stale validatedForm
        newKratosSdk()
            .updateRecoveryFlow(recoveryRequest, options)
            .then(({ data }) => {
                const newToken: string = csrfFromUiContainer(data.ui)
                if (newToken === '') {
                    console.debug('Re-Initializing recovery flow')
                    newKratosSdk()
                        .createBrowserRecoveryFlow()
                        .then(({ data }) => {
                            setFlow(data)
                            setForm(form.set('csrfToken', csrfFromUiContainer(data.ui)))
                        })
                        .catch((err: AxiosError) => {
                            switch (err.response?.status) {
                                case 400:
                                    console.error(
                                        'Error when initializing self service recovery flow...'
                                    )
                            }
                            console.error(err)
                        })
                } else {
                    setFlow(data)
                    setForm(form.set('csrfToken', newToken))
                }
                // Get the message from the response
                if (data.ui.messages !== undefined && data.ui.messages.length > 0) {
                    const message = data.ui.messages[0]
                    // A flow expiration will successfully set the new flow and CSRF data to resubmit the form for another attempt
                    if (message.id === FlowExpirationError) {
                        setBanner(newErrorBanner(message.text))
                        return
                    }
                    setBanner(newSuccessBanner(message.text))
                }
            })
            .catch((err: AxiosError<any, any>) => {
                switch (err.response?.status) {
                    case 400:
                        // Status code 400 implies the email validation had an error
                        setFlow(err.response?.data)
                        const csrfToken = csrfFromUiContainer(err.response?.data.ui)
                        setForm(form.set('csrfToken', csrfToken))
                        return
                    case 403:
                        setBanner(newErrorBanner(err.response?.data.error.reason))
                        return
                    case 404:
                        setBanner(recoveryFlowExpiredBanner)
                        return
                    case 422:
                        // Generally the 422 failure is created by the auth proxy on a captcha related error
                        const message = parseUINodeErrors(err.response?.data)[0]
                        setBanner(newErrorBanner(message.messages[0].text))
                        return
                    default:
                        console.error(err)
                }
            })
    }

    return (
        <Layout heading="Recover Account" subheader={RecoverAccountSubheader} banner={banner}>
            <SmallContainer>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextFormField
                            id="email"
                            label="Email Address"
                            value={form.values.email}
                            message={form.messages.email}
                            required={true}
                            autoFocus={true}
                            onBlur={onBlurUpdateForm('email', form, setForm)}
                            onInput={onInputUpdateForm('email', form, setForm)}
                            onKeyDown={handleEnterPress(handleSubmitRecovery)}
                            InputProps={{
                                placeholder: 'pat@acme.co',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CaptchaInput
                            id="captcha"
                            setCaptcha={onCaptchaUpdateForm('captchaToken', form, setForm)}
                            message={form.messages.captchaToken}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            marginBottom: '10px',
                            display: 'flex',
                            justifyContent: 'left',
                        }}
                    >
                        <Button id="reset-btn" onClick={handleSubmitRecovery}>
                            Recover Account
                        </Button>
                    </Grid>
                </Grid>
            </SmallContainer>
        </Layout>
    )
}

export default RecoverAccount

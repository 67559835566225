import React, { ReactElement, FC } from 'react'
import { Typography } from '@mui/material'
import { CenterDialogButton, DialogProps, TokensDialog, voidClose } from './base'

export interface CreateDialogProps extends DialogProps {
    createdToken: string
    onClose: voidClose
}

const CreateDialog: FC<CreateDialogProps> = (props: CreateDialogProps): ReactElement => {
    const { onClose, open, createdToken } = props

    const onDialogClose = (_: object, reason: string) => {
        // We don't want to close when the backdrop is clicked. For more details about
        // the event and reason inputs: https://mui.com/material-ui/api/dialog/
        if (reason === 'backdropClick') {
            return
        }
        onClose()
    }

    return (
        <TokensDialog
            title={'You just created a Personal Access Token!'}
            subtitle={
                'This token value will NOT be saved. Take a copy of this token to use it later:'
            }
            closeButton={false}
            onClose={onDialogClose}
            open={open}
        >
            <Typography
                paddingTop="0.5em"
                component="h6"
                variant="subtitle1"
                fontWeight="bold"
                textAlign="center"
            >
                {createdToken}
            </Typography>
            <CenterDialogButton id="create-token-btn" text="I saved the token" onClick={onClose} />
        </TokensDialog>
    )
}

export default CreateDialog
